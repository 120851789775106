import { Component, computed, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetContractRequestOffers } from 'src/app/store/contract-requests/contract-requests.actions';
import { ContractRequestsState } from 'src/app/store/contract-requests/contract-requests.state';

@Component({
  selector: 'app-contract-requests-smart',
  templateUrl: './contract-requests-smart.component.html',
  styleUrls: ['./contract-requests-smart.component.scss'],
  standalone: false
})
export class ContractRequestsSmartComponent implements OnInit {
    contractRequests = this.store.selectSignal( ContractRequestsState.contractRequests );
    contractRequestsLoading = this.store.selectSignal( ContractRequestsState.loading );
    dealContractRequest = computed(() => {
      const contractRequests = this.contractRequests() || [];
      return contractRequests.find( contractRequest => contractRequest.dealId === this.dealId );
    });

    @Input() dealId: number;
    @Input() loanProductName: string;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.store.dispatch(new GetContractRequestOffers(this.dealId));
    }
}
