import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { ErrorHandler, Injectable } from '@angular/core';
import { OffersService } from 'src/app/services/offers.service';
import { GetDealOffers, ClearOffersStore, CreateOffer, CreatePPPOffer, CreateAndAcceptOffer, AcceptOffer, PublishOffer, UnpublishOffer } from './offers.actions';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { GetApplicationDetails } from '../application-details/application-details.actions';
import { EventBusService } from 'src/app/services/event-bus.service';
import { formatISO } from 'date-fns';
import { ResponseObj } from '@app/app/interfaces/response-obj';

export class  OffersStateModel {
    offers: any[] | null;
    loading: boolean;
}

@State<OffersStateModel>({
    name: 'offers',
    defaults: {
        offers: null,
        loading: false
    }
})
@Injectable()

export class OffersState {

    @Selector() static offers(state: OffersStateModel) {
        return state.offers;
    }

    @Selector() static loading(state: OffersStateModel) {
        return state.loading;
    }

    constructor(
        private offersService: OffersService,
        private store: Store,
        private eventBusService: EventBusService,
        private _errorHandler: ErrorHandler
    ) {}

    @Action(GetDealOffers)
    getDealOffers({ patchState }: StateContext<OffersStateModel>, { dealId }: GetDealOffers) {
        return this.offersService.getDealOffers(dealId).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to retrieve offers. Please refresh the page to try again.'
                }));
                return throwError(err);
            }), tap((response: any) => {
            response.data.forEach((offer: { accepted: any; declined: any; status: string; }) => {
                if (offer.accepted && !offer.declined) {
                    offer.status = 'Accepted';
                } else if (offer.declined && !offer.accepted) {
                    offer.status = 'Rejected';
                } else if (!offer.accepted && !offer.declined) {
                    offer.status = '';
                } else {
                    offer.status = '';
                }
            });
            patchState({ offers: response.data });
        }));
    }

    @Action(CreateOffer)
    createOffer({ patchState }: StateContext<OffersStateModel>, { dealId, packagedOffer }: CreateOffer) {
        patchState({ loading: true });
        return this.offersService.createOffer(dealId, packagedOffer).pipe(catchError((err: any) => {
                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: 'Unable to create offer. Please try again.'
                }));
                patchState({ loading: false });
                return throwError(err);
            }), tap(() => {
            this.store.dispatch(new CreateNewAlert({
                level: 'success',
                message: 'Offer created successfully!'
            }));
            this.store.dispatch(new GetDealOffers(dealId));
            this.store.dispatch(new GetApplicationDetails(dealId));
            this.eventBusService.publish(new this.eventBusService.types.NewOfferEvent());
            patchState({ loading: false });
        }));
    }

    @Action(CreateAndAcceptOffer)
    createAndAcceptOffer(
        { patchState }: StateContext<OffersStateModel>,
        { dealId, packagedOffer }: CreateAndAcceptOffer
    ) {
        patchState({ loading: true });
        return this.offersService.createOffer(dealId, packagedOffer).pipe(
            switchMap((createOfferResponse) => {
                return this.offersService.updateOffer(
                    createOfferResponse.data.id,
                    { accepted: formatISO(new Date()) }
                ).pipe(
                    catchError((err) => {
                        patchState({ loading: false })
                        this._errorHandler.handleError(err);
                        return throwError(() => err);
                    }),
                    tap(() => {
                        patchState({ loading: false });
                        this.store.dispatch(new GetDealOffers(dealId));
                        this.store.dispatch(new GetApplicationDetails(dealId));
                    })
                );
            }),
            catchError((err) => {
                patchState({ loading: false })
                this._errorHandler.handleError(err);
                return throwError(() => err);
            })
        );
    }

    @Action(CreatePPPOffer)
    createPPPOffer({ patchState }: StateContext<OffersStateModel>, { dealId, packagedOffer }: CreatePPPOffer) {
        patchState({ loading: true });
        return this.offersService.createPPPOffer(dealId, packagedOffer).pipe(catchError((err: any) => {
                const messageExists = err && err.error && err.error.errors.length > 0 && err.error.errors[0] && err.error.errors[0].message;

                this.store.dispatch(new CreateNewAlert({
                    level: 'error',
                    message: messageExists ? 'Unable to upload contract. ' + err.error.errors[0].message : 'Unable to upload contract. Please try again.'
                }));
                patchState({ loading: false });
                return throwError(err);
            }), tap(() => {
            this.store.dispatch(new CreateNewAlert({
                level: 'success',
                message: 'Contract uploaded successfully!'
            }));
            this.store.dispatch(new GetDealOffers(dealId));
            this.store.dispatch(new GetApplicationDetails(dealId));
            this.eventBusService.publish(new this.eventBusService.types.NewOfferEvent());
            patchState({ loading: false });
        }));
    }

    @Action(AcceptOffer)
    acceptOffer({ patchState }: StateContext<OffersStateModel>, { offerId }: AcceptOffer) {
        patchState({ loading: true });
        return this.offersService.updateOffer(offerId, { accepted: formatISO(new Date()) })
            .pipe(
                catchError((err) => {
                    patchState({ loading: false });
                    this._errorHandler.handleError(err);
                    return throwError(() => err);
                }),
                tap(() => patchState({ loading: false }))
            );
    }

    @Action(ClearOffersStore)
    clearOffersStore({ patchState }: StateContext<OffersStateModel>, {}: ClearOffersStore) {
        patchState({ offers: null });
    }

    @Action(PublishOffer)
    publishOffer(ctx: StateContext<OffersStateModel>, { offerId }: PublishOffer) {
      this.offersService.publishOption(offerId, 'offer').subscribe({
        next: (response) => this.handlePublishNext(response, offerId, ctx)
      })
    }

    @Action(UnpublishOffer)
    unpublishOffer(ctx: StateContext<OffersStateModel>, { offerId }: UnpublishOffer) {
      this.offersService.unpublishOption(offerId, 'offer').subscribe({
        next: (response) => this.handlePublishNext(response, offerId, ctx)
      })
    }

    handlePublishNext(response: ResponseObj, offerId: number, { getState, patchState }: StateContext<OffersStateModel>) {
      if (response?.status?.toLowerCase() === 'success') {
        const updates = response.data;
        if (!updates) {
          return;
        }
        const state = getState();

        const offers = state.offers?.map(offer => {
          if (offer.id === offerId) {
            return { ...offer, ...updates };
          }
          return offer;
        }) ?? null;

        patchState({
          offers,
        });
      }
    }
}
