import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { EarlyAccessFeatureService } from "@app/app/components/settings/early-access/early-access-feature.service";
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SaasNewFeatureBadgeComponent } from '../saas-new-feature-badge/saas-new-feature-badge.component';
import { slideAnimation } from '@app/app/animations/slide/slide.animation';
import { Store } from '@ngxs/store';
import { AuthState } from '@app/app/store/auth/auth.state';
import { FadeAnimationDirective } from '@app/app/animations/fade/fade-animation.directive';
import { fadeAnimation } from '@app/app/animations/fade/fade.animation';
import { animate, state, style, transition, trigger } from '@angular/animations';

type SidenavUserMenuState = 'open' | 'closed';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    SaasNewFeatureBadgeComponent,
    FadeAnimationDirective,
  ],
  selector: 'app-sidenav-user',
  templateUrl: './sidenav-user.component.html',
  styleUrls: ['./sidenav-user.component.scss'],
  animations: [
    slideAnimation,
    fadeAnimation,
    trigger('buttonGrow', [
      state('open', style({
        width: '{{ openWidth }}px',
        height: '66px',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      }), {params: { openWidth: 200 }}),
      state('close', style({
        width: '56px',
        height: '56px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
      })),
      transition('closed => open', [
        animate('200ms cubic-bezier(.4, .22, .31, 1.01)')
      ]),
      transition('open => closed', [
        animate('200ms 200ms cubic-bezier(.4, .22, .31, 1.01)')
      ]),
    ]),
    trigger('menuGrow', [
      state('open', style({
        height: '112px',
        padding: '8px 0',
        overflow: 'hidden',
        borderLeft: '0.5px solid #595E5F',
        borderRight: '0.5px solid #595E5F',
        borderTop: '0.5px solid #595E5F'

      })),
      state('closed', style({
        height: '0',
        overflow: 'hidden',
        padding: '0',
        borderColor: 'transparent'
      })),
      transition('closed => open', [
        animate('200ms 200ms cubic-bezier(.4, .22, .31, 1.01)')
      ]),
      transition('open => closed', [
        animate('200ms cubic-bezier(.4, .22, .31, 1.01)'),
      ])
    ])
  ]
})
export class SidenavUserComponent implements AfterViewInit {
  @ViewChild('sidenavUserMenuText') userMenuTextEl: ElementRef | undefined;
  @ViewChild('sidenavUserComponent') sidenavUserComponentEl: ElementRef | undefined;
  @Input() collapsed = false;
  @Input() navMenuCollapsed = false;
  @Input() userFullName!: string;
  @Input() institutionName!: string;
  @Input() organizationName!: string;
  @Output() userLogout: EventEmitter<any> = new EventEmitter();
  @Output() userSettings: EventEmitter<any> = new EventEmitter();
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.sidenavUserComponentEl?.nativeElement.contains(event.target)) {
      this.sidenavUserMenuState.set('closed');
    }
  }
  isSbaSpecialist = this._store.selectSignal(AuthState.isSbaSpecialist);
  saasFeatureBadgeEnabled = computed(() => this._earlyAccessService.earlyAccessShowBadge());
  navV2 = this._store.selectSignal(AuthState.userHasPermission('NavigationV2DarkLaunch'));
  sidenavUserMenuState = signal<SidenavUserMenuState>('closed');
  sidenavMenuTextWidth = signal(0);
  menuOpenWidth = computed(() => {
    return this.isSbaSpecialist()
      ? this.sidenavMenuTextWidth() + 174
      : this.sidenavMenuTextWidth() + 72
  })

  constructor(
    private _earlyAccessService: EarlyAccessFeatureService,
    private _store: Store
  ) { }

  ngAfterViewInit(): void {
    const userMenuTextWidth = this.userMenuTextEl?.nativeElement.clientWidth;
    this.sidenavMenuTextWidth.set(userMenuTextWidth);
  }

  selectLogout(): void {
    this.userLogout.emit();
  }

  selectSettings(): void {
    this.userSettings.emit();
  }

  toggleSidenavMenu(): void {
    const newMenuState = this.sidenavUserMenuState() === 'open' ? 'closed' : 'open';
    this.sidenavUserMenuState.set(newMenuState);
  }
}
