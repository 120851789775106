import { Approval } from '../../../app/interfaces/approval/approval.model';//
import { ApprovalCalculatorStateModel } from './approvals.state';

export class SubscribeToApprovals {
	static readonly type = '[Approval] SubscribeToApprovals';
	constructor(public dealId: number) {}
}

export class GetApprovals {
	static readonly type = '[Approval] GetApprovals';
	constructor(
		public dealId: number,
	) {}
}

export class GetApprovalsCount {
	static readonly type = '[Approval] GetApprovalsCount';
	constructor(public borrowerId: number) {}
}

export class UpdateApproval {
	static readonly type = '[Approval] Update';
	constructor(public offer: Approval) {}
}

export class SetApprovalCalculator {
  static readonly type = '[Approval Calculator] SetApprovalCalculator';
  constructor(public approvalCalculatorState: ApprovalCalculatorStateModel) {}
}

export class GetApprovalCalculatorStorage {
  static readonly type = '[Approval Calculator] GetApprovalCalculatorStorage';
  constructor() {}
}

export class ClearApprovalsStore {
  static readonly type = '[Approval] ClearApprovalsStore';
  constructor() {}
}

export class GetAdjustedApproval {
	static readonly type = '[Approval] GetAdjustedApproval';
	constructor(
		public approvalId: number,
		public amount: number,
		public term: number,
		public points: number,
		public frequency: string,
		public origination: number) {}
}

export class PublishApproval {
	static readonly type = '[Approval] PublishApproval';
	constructor(
		public approvalId: number,
	) {}
}

export class UnpublishApproval {
	static readonly type = '[Approval] UnpublishApproval';
	constructor(
		public approvalId: number,
	) {}
}
