import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DynamicDocumentCellComponent } from './cell-components/dynamic-document-cell/dynamic-document-cell.component';
import localeData from '@angular/common/locales/en';
import { camelCaseFormatter } from '@app/app/components/grid-components/grid-formatters';
import { dateFilterComparator } from '@app/app/components/grid-components/grid-comparitors';
import * as moment from 'moment-timezone';
import { FileNameCellComponent } from './cell-components/filename-cell/filename-cell.component';

registerLocaleData(localeData);
const contactsArrayFormatter = (contacts: { first: string; last: string; email: string; }[]) => {
  return contacts
    .sort()
    .map(contact => {
      const first = contact?.first.charAt(0).toUpperCase() + contact?.first.slice(1).toLowerCase();
      const last = contact?.last.charAt(0).toUpperCase() + contact?.last.slice(1).toLowerCase();
  
      return `${first} ${last}`;
    })
    .join(', ');
}

export const COL_DEF_FILE_NAME: ColDef = {
  headerName: 'File name',
  headerClass: 'filename-header-cell',
  field: 'filename',
  colId: 'filename',
  filter: 'agTextColumnFilter',
  cellRenderer: FileNameCellComponent,
  headerCheckboxSelection: true,
  filterParams: {
    filterOptions: ['startsWith', 'contains'],
  },
  comparator: (valueA, valueB) => {
    const nameA: string = valueA.trim();
    const nameB: string = valueB.trim();
    return nameA?.localeCompare(nameB);
  }
};

export const COL_DEF_TYPE: ColDef = {
  headerName: 'Type',
  field: 'category',
  colId: 'category',
  filter: 'agSetColumnFilter',
  cellClassRules: {
    'text-lendio-warm-gray-400': params => params.data.scanStatus !== 'CLEAN'
  },
  valueFormatter: ({ value, data }: ValueFormatterParams) => {
    let formattedValue = '';

    if (value?.match('^pL')) {
      const _formattedValue = camelCaseFormatter({ value: value.slice(2) });
      formattedValue = `P&L ${_formattedValue.charAt(0).toLowerCase()}${_formattedValue.slice(1)}`
    } else {
      formattedValue = camelCaseFormatter({ value });
    }

    const dateValue = data.months && data.months.length ? ` ${data.months[0]}` : (
      data.years && data.years.length ? ` ${data.years[0]}` : ''
    );

    return  `${formattedValue}${dateValue}`;
      
  },
  comparator: (valueA, valueB) => {
    const typeA: string = valueA.trim();
    const typeB: string = valueB.trim();
    return typeA?.localeCompare(typeB);
  }
};


export const COL_DEF_UPLOADED: ColDef = {
  headerName: 'Uploaded on',
  field: 'created',
  colId: 'created',
  filter: 'agDateColumnFilter',
  filterParams: {
    maxValidDate: moment().format('YYYY-MM-DD'),
    comparator: dateFilterComparator,
    filterOptions: [
      'lessThan',
      'greaterThan',
      'inRange'
    ],
    defaultOption: 'lessThan',
    inRangeInclusive: true,
  },
  cellClassRules: {
    'text-lendio-warm-gray-400': params => params.data.scanStatus !== 'CLEAN'
  },
  sortingOrder: ['asc', 'desc', null],
  valueFormatter: ({ value }: ValueFormatterParams) => {
    return value
      ? moment.unix(value).fromNow()
      : ''
  },
  comparator: (valueA, valueB) => {
    return valueA - valueB;
  }
};

export const COL_DEF_DYNAMIC_ACTIONS: ColDef = {
  hide: false,
  headerName: 'Accept/Reject',
  field: 'status',
  colId: 'status',
  cellRenderer: DynamicDocumentCellComponent,
  filter: 'agSetColumnFilter',
  valueGetter: (params: ValueGetterParams) => {
    return params.data.status?.accepted ? 'Accepted' : 
      (params.data.status?.rejected ? 'Rejected' : null);
  },
  sortable: true,
  cellStyle: { 
    display: 'flex',
    justifyContent: 'start'
  },
};

export const COL_DEF_DYNAMIC_BORROWER_ACTIONS: ColDef = {
  hide: false,
  headerName: 'Actions',
  field: 'actions',
  colId: 'actions',
  cellRenderer: DynamicDocumentCellComponent,
  sortable: false,
  valueGetter: (params: ValueGetterParams) => {
    return null
  },
  cellStyle: { 
    display: 'flex',
    justifyContent: 'start'
  },
};

export const COL_DEF_SCAN_STATUS: ColDef = {
  hide: true,
  headerName: 'Scan',
  field: 'scanStatus',
  colId: 'scanStatus',
  filter: 'agSetColumnFilter',
  comparator: (valueA, valueB) => {
    const nameA: string = valueA.trim();
    const nameB: string = valueB.trim();
    return nameA?.localeCompare(nameB);
  }
};

export const COL_DEF_CONTACTS: ColDef = {
  hide: false,
  headerName: 'Signer/Contacts',
  field: 'contacts',
  colId: 'contacts',
  cellClassRules: {
    'text-lendio-warm-gray-400': params => params.data.scanStatus !== 'CLEAN'
  },
  filter: 'agTextColumnFilter',
  valueGetter: (params: ValueGetterParams) => {
    return contactsArrayFormatter(params.data.docusignEnvelope?.contacts ?? []);
  },
  sortable: true,
  cellStyle: { 
    display: 'flex',
    justifyContent: 'start'
  },
};
