import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/app/components/dialogs/confirm-dialog/confirm-dialog.component';
import { BorrowerValue, BorrowerValues } from '@app/app/interfaces/borrower-value.model';
import { Business } from '@app/app/interfaces/business.model';
import { NaicsObject } from '@app/app/interfaces/naics-object.model';
import { FormOptionsService } from '@app/app/services/form-options.service';
import { NaicsSearchService } from '@app/app/services/naics-search.service';
import { BusinessActions } from '@app/app/store/businesses/business.actions';
import { Store } from '@ngxs/store';
import { Subject, Subscription, take } from 'rxjs';
import * as _ from 'underscore';
import { fadeIn } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-edit-business-dialog',
  templateUrl: './edit-business-dialog.component.html',
  styleUrls: ['./edit-business-dialog.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', useAnimation(fadeIn, { params: { timing: 0.5 } }))
    ])
  ],
  standalone: false
})
export class EditBusinessDialogComponent implements OnInit, OnDestroy {

  @Output() onSaveSuccess = new EventEmitter<void>();
  private _destroySubs: Subject<void> = new Subject<void>();
  public today: Date = new Date();

  beneficiariesSub: Subscription = new Subscription();
  managingPartnersSub: Subscription = new Subscription();



  form: FormGroup;

  // Track mat-accordion expansion panels (sections) so one is always open.
  // business, operations, or ownership
  openSection: string;

  // Validation regex patterns.
  // Note: angular can be quirky when you try to add these strings directly.
  // See https://stackoverflow.com/questions/50508712/validate-an-url
  private validUrl = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";

  // Lists for select fields in form.
  businessLocationTypeOptions: Array<any>;
  businessPropertyTypeOwnRentOptions: Array<any>;
  entityTypeOptions: Array<any>;
  lendioIndustryOptions: Array<any>;
  primaryCustomerOptions: Array<any>;
  stateOptions: Array<any>;
  yesNoOptions: Array<any>;

  saveError: string;
  hideEditModal: boolean = false;
  todayDate = new Date();

  // NAICS search autocomplete vars.
  // TODO: Separate the NAICS search functionality into separate component
  // or make the existing one configurable.
  naicsCode: number;
  // This is either the displayed NAICS label (if there is a code when the
  // form loads) or the search text entered by user to find a new code.
  searchText: string;
  loading = true;
  searchResults: NaicsObject[] = [];
  searching = false;
  hasSearched = false;
  errored = false;

  /**
   * Inject data from the client via `data`.
   */
  constructor (
    private naicsSearch: NaicsSearchService,
    private store: Store,
    public formOptionsService: FormOptionsService,
    // Used for confirmation dialog when cancelling dirty form.
    public confirmDialog: MatDialog,
    public selfDialogRef: MatDialogRef<EditBusinessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number, section: string, business: Business},
  ) {
    this.search = _.debounce(this.search, 600);
  }

  ngOnInit(): void {
    // Set the initial open accordion section.
    this.openSection = this.data.section;

    // Load form options.
    this.getFormOptions();

    // Set the NAICS code from the borrower value if it exists.
    this.naicsCode = parseInt(this.data.business?.borrowerValues['670'].value);

    this.createForm(this.data.business);

    // NAICS autocomplete initialize.
    if (this.naicsCode && this.naicsCode.toString().length === 6) {
      this.naicsSearch.searchByCode(this.naicsCode).pipe(take(1)).subscribe({
        next: (res) => {
        // If we find a match, show it in the form field.
          if (res && 'data' in res && res.data.hasOwnProperty('code')) {
            this.searchText = res.data.title;
          }
          this.loading = false;
          this.errored = false;
        },
        error: () => {
          // If the request errors, just let the text be blank
          this.loading = false;
          this.errored = true;
        }
      });
    } else {
      // Invalid code.
      this.loading = false;
    }

    // This checks the forms initial values to highlight any invalid data from
    // the server (e.g. invalid website like 'N/A') so user sees what to change.
    this.form.markAllAsTouched();

    this.managingPartnersSub = this.managingPartnersControl.valueChanges
      .pipe(takeUntil(this._destroySubs))
      .subscribe((value: number) => {
        if (value < 0) {
          this.managingPartnersControl.setValue(0);
        }
    });

    this.beneficiariesSub = this.beneficiariesControl.valueChanges
      .pipe(takeUntil(this._destroySubs))
      .subscribe((value: number) => {
      if (value < 0) {
        this.beneficiariesControl.setValue(0);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroySubs.next();
    this._destroySubs.complete();
  }

  createForm(b: Business | null) {
    if (!b) return;
    const bvs = b?.borrowerValues;
    const pc = b.primaryContact;

    this.form = new FormGroup({
      // Always read-only.
      primaryContact: new FormControl({
        value: (pc?.first + ' ' + pc?.last) ?? '',
        disabled: true
      }),
      // Always read-only.
      borrowerCreated: new FormControl({
        value: b?.created ? new Date(b.created).toLocaleDateString() : '',
        disabled: true
      }),
      borrowerPhone: new FormControl(b?.phone),
      borrowerStreet: new FormControl(b?.street),
      borrowerStreet2: new FormControl(b?.street2),
      borrowerCity: new FormControl(b?.city),
      borrowerState: new FormControl(b?.stateId),
      borrowerZip: new FormControl(b?.zipId),
      '226': new FormControl({ value: bvs['226'].value, disabled: this.isDisabled(bvs['226']) }),
      '232': new FormControl({ value: bvs['232'].value, disabled: this.isDisabled(bvs['232']) }),
      '228': new FormControl({ value: bvs['228'].value, disabled: this.isDisabled(bvs['228']) }, [
        Validators.pattern(this.validUrl)
      ]),
      '230': new FormControl({ value: bvs['230'].value, disabled: this.isDisabled(bvs['230']) }),
      '732': new FormControl({ value: bvs['732'].value, disabled: this.isDisabled(bvs['732']) }),
      // Hidden NAICS code (the borrower value).
      '670': new FormControl({ value: bvs['670'].value, disabled: this.isDisabled(bvs['670']) }),
      // NAICS label - value shown in search box.
      'naicsSearchText': new FormControl({ value: this.searchText, disabled: this.isDisabled(bvs['670']) }),
      '238': new FormControl({ value: bvs['238'].value
          ? new Date(bvs['238'].value)
          : '',
        disabled: this.isDisabled(bvs['238']) }),
      '658': new FormControl({ value: bvs['658'].value, disabled: this.isDisabled(bvs['658']) }),
      '234': new FormControl({ value: bvs['234'].value, disabled: this.isDisabled(bvs['234']) }),
      '86': new FormControl({ value: bvs['86'].value, disabled: this.isDisabled(bvs['86']) }),
      '725': new FormControl({ value: bvs['725'].value, disabled: this.isDisabled(bvs['725']) }),
      '704': new FormControl({ value: bvs['704'].value, disabled: this.isDisabled(bvs['704']) }),
      '727': new FormControl({ value: bvs['727'].value, disabled: this.isDisabled(bvs['727']) }),
      '676': new FormControl({ value: bvs['676'].value, disabled: this.isDisabled(bvs['676']) }),
      '244': new FormControl({ value: bvs['244'].value, disabled: this.isDisabled(bvs['244']) }),
      '246': new FormControl({ value: bvs['246'].value, disabled: this.isDisabled(bvs['246']) }),
      '806': new FormControl({ value: bvs['806'].value, disabled: this.isDisabled(bvs['806']) }),
      '807': new FormControl({ value: bvs['807'].value, disabled: this.isDisabled(bvs['807']) }),
      '712': new FormControl({ value: bvs['712'].value, disabled: this.isDisabled(bvs['712']) }),
      '714': new FormControl({ value: bvs['714'].value
          ? new Date(bvs['714'].value)
          : '',
        disabled: this.isDisabled(bvs['714']) }),
      '236': new FormControl({ value: bvs['236'].value, disabled: this.isDisabled(bvs['236']) }),
    });
  }

  get beneficiariesControl(): AbstractControl {
    return this.form.get('806')!;
  }

  get managingPartnersControl(): AbstractControl {
    return this.form.get('807')!
  }

  /**
   * Disable borrower values with external source IDs.
   *
   * See optimus.borrowerValuesSources table for reference.
   *   null (no sourceId), 1 (undefined), 2 (borrower), 5 (lendio_user),
   *   6 (lendio_system), 9 (lender).
   */
  private isDisabled(bv: BorrowerValue) {
    const enabled = [null, 1, 2, 5, 6, 9];
    if (bv?.sourceId) {
      return !(enabled.indexOf(bv?.sourceId) > -1);
    } else {
      return false;
    }
  }

  // Load all the form options from the service.
  private getFormOptions() {
    this.businessLocationTypeOptions = this.formOptionsService.businessLocationType();
    this.businessPropertyTypeOwnRentOptions = this.formOptionsService.businessPropertyTypeOwnRent();
    this.entityTypeOptions = this.formOptionsService.entityTypes();
    this.lendioIndustryOptions = this.formOptionsService.lendioIndustry();
    this.primaryCustomerOptions = this.formOptionsService.primaryCustomer();
    this.stateOptions = this.formOptionsService.states();
    this.yesNoOptions = this.formOptionsService.yesNo();
  }

  // Autocomplete methods for NAICS search.
  search(): void {
    if (this.searchText.length >= 3) {
      this.searching = true;
      this.hasSearched = true;
      this.naicsSearch.searchByTerms(this.searchText).pipe(take(1)).subscribe({
        next: (res) => {
          this.searchResults = res.data;
          this.searching = false;
          this.errored = false;
        },
        error: (err) => {
          this.searching = false;
          this.errored = true;
        }
      });
    }
  }

  // Get examples of NAICS codes.
  getExamples(examples: any[]): string {
    if (!examples) {
      return '';
    }
    let response = 'Examples:\n\n';
    examples.forEach((example) => {
      response += example + '\n';
    });
    return response;
  }

  // Set the NAICS code from the selected result.
  setCode(code: number, text: string): void {
    this.searchText = text;
    this.naicsCode = code;
    // Update the naics borrower value (code) to the new selection.
    this.form.controls['670'].setValue(code);
    this.searchResults = [];
  }

  /**
   * Save changed values to store and database.
   *
   * This is only enabled if at least one values changed.
   */
  save(form: FormGroup): void {
    // Grab the borrower values from the form and translate back to IDs
    // since they're unique. Aliases are "supposed to" be unique, but it's
    // not enforced by the DB.
    const bvsObject = this.data.business?.borrowerValues;
    let bvs: BorrowerValues  =  {}
    for (const key in bvsObject) {
      bvs[key] = {
        id: bvsObject[key].id,
        value: form.value[key],
        borrowerId: this.data.id,
        attributeId: bvsObject[key].attributeId,
        sourceId: bvsObject[key].sourceId,
      }
    }

    // Grab the business fields from the form.
    const b = {
      // This can never be updated.
      // created: form.value['borrowerCreated'],
      phone: form.value['borrowerPhone'],
      street: form.value['borrowerStreet'],
      street2: form.value['borrowerStreet2'],
      city: form.value['borrowerCity'],
      stateId: form.value['borrowerState'],
      zipId: form.value['borrowerZip'],
      borrowerValues: bvs,
    }
    // Combine the new business values with those provided in this.data.
    // The form values will take precedence.
    const business: Business = { ...this.data.business, ...b };

    // The `overview` component will reload with the updated values.
    // The Update action will trigger showing toast.
    this.store.dispatch(new BusinessActions.UpdateBusiness(business)).subscribe({
      next: () => {
        this.selfDialogRef.close();
        // Let the parent component know when this succeeds.
        this.onSaveSuccess.emit();
      },
      error: (e) => this.saveError = 'There was a problem saving this business',
    });
  }

  cancel($event: Event) {
    if (this.form.dirty) {
      $event.stopPropagation();
      // Hide current modal to avoid modal-on-modal.
      this.hideEditModal = true;

      let confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        data: {
          title: "Are you sure you want to leave without saving?",
          description: "If you leave this page, any changes you've made will be lost.",
          cancelLabel: "Keep editing",
          confirmLabel:"Discard changes",
          width: "352px"
        }
      });

      // Cancel the "cancel" of the edit dialog.
      confirmDialogRef.componentInstance.onCancel.subscribe(() => {
        confirmDialogRef.close();
        this.hideEditModal = false;
      });
      // Proceed with cancelling the edit dialog.
      confirmDialogRef.componentInstance.onConfirm.subscribe(() => {
        confirmDialogRef.close();
        this.selfDialogRef.close();
      });
    } else {
      // If not dirty, just close.
      this.selfDialogRef.close();
    }
  }

  // Ensure one accordion section (mat-extension-panel) is always open.
  sectionOpened(section: string) {
    this.openSection = section;
  }
  // If user closes a section rather than opening a new one, auto-open the next
  // in the order business, operations, ownership.
  sectionClosed(section: string) {
    // if (openSection != 'ownership') setSection('ownership');
    if (section == this.openSection) {
      if (section == 'business') this.openSection = 'operations';
      if (section == 'operations') this.openSection = 'ownership';
      if (section == 'ownership') this.openSection = 'business';
    }
  }

  preventOutOfRangeEntry(value, control): void {

  }

}
