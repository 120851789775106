@if (loadingService.isLoading | async) {
    <app-loading [@fadeInOut]></app-loading>
} @else {
    <span [@fadeInOut]>
        <app-snackbar-manager></app-snackbar-manager>
        <ng-template #FeaturesLoading>
            <app-dot-loader-scale class="app-root-dot-loader"></app-dot-loader-scale>
        </ng-template>

        @if (siteIsUpdated) {
            <app-alert class="site-updated-alert" level="info"
                       message="Updates available! Please hard refresh at your earliest convenience. Windows: Shift+Ctrl+R. Mac: Shift+Command+R.">
            </app-alert>
        }

        @if (unsupportedBrowser) {
            <div class="unsupported-browser">
                Your current browser is not supported. To improve your experience using Lendio's Partner Portal, please upgrade to a
                supported browser such as
                <a class="text-blue-500 hover:text-blue-800" href="https://www.google.com/chrome/" target="_blank">Google Chrome</a>, or
                <a class="text-blue-500 hover:text-blue-800" href="https://www.microsoft.com/en-us/edge" target="_blank">Microsoft
                    Edge</a>.
            </div>
        }

        @if ((currentUser$ | async) && !routeContainsAuth) {
            @if (sassFeaturesLoaded$ | async) {
                @if (navV2()) {
                    @if (globalSearchVisible) {
                        <div class="sidenav-search-view-content"
                             [appSlideAnimation]="globalSearchVisible"
                             [slideEnterDurationInMs]="250"
                             [slideLeaveDurationInMs]="200">
                            <search-input (searchViewClosed)="closeGlobalSearch()" />
                        </div>
                        <div class="sidenav-search-view-curtain"
                             (mousedown)="closeGlobalSearch()"
                             [appFadeAnimation]
                             [fadeEnterDurationInMs]="250"
                             [fadeEnterDelayInMs]="0"
                             [fadeLeaveDurationInMs]="250"
                             [fadeLeaveDelayInMs]="0"
                             [fadeOpacityPercent]="75">
                        </div>
                    }
                }
                <mat-sidenav-container [class.nav-v2]="navV2()">
                    <mat-sidenav #lendioSidenav
                                 id="lendioSidenav"
                                 [class.nav-v2]="navV2()"
                                 data-cy="sidenav"
                                 mode="side"
                                 disableClose
                                 [opened]="navCollapsed">
                        @if (navV2()) {
                            <div class="sidenav-brand-logo-container">
                                <img class="sidenav-brand-logo"
                                     src="assets/images/logomark - light.svg"
                                     alt="lendio">
                            </div>

                        } @else {
                            <div class="lendio-logo-container">
                                <img class="lendio-logo"
                                     [src]="!lendioSidenav.opened
                                                ? 'assets/images/logomark - light.svg'
                                                : 'assets/images/logo - light.svg'"
                                     alt="lendio">
                            </div>
                            @if (lendioSidenav.opened) {
                                <button mat-icon-button
                                        class="button-nav-size nav-collapse"
                                        (click)="lendioSidenav.close()">
                                    <mat-icon class="nav-collapse-icon"
                                              fontSet="material-icons-outlined">
                                        <span>start</span>
                                    </mat-icon>
                                </button>
                            }
                        }
                        <mat-nav-list [class.nav-v2]="navV2()">
                             @if (navV2()) {
                                 <button mat-list-item class="lendio-nav-item-button" (click)="openGlobalSearch()">
                                     <div class="lendio-nav-item-state-layer">
                                         <mat-icon fontSet="material-icons-outlined">search</mat-icon>
                                     </div>
                                     <span class="lendio-nav-item-name">Search</span>
                                 </button>
                             }

                            <!-- Dashboard -->
                            @if ((currentUser$ | async)?.isLender() && !(currentUser$ | async)?.institution?.isPPPLender) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'dashboard'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Dashboard' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                     @if (navV2()) {
                                         <div class="lendio-nav-item-state-layer">
                                             <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                 <span>dashboard</span>
                                             </mat-icon>
                                         </div>
                                         <span class="lendio-nav-item-name">Dashboard</span>
                                     } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>dashboard</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Dashboard' | titlecase }}</span>
                                        }
                                     }
                                </a>
                            }

                            <!-- Partner Dashboard -->
                            @if (((currentUser$ | async)?.isReferralPartner()) && ((hasAffiliateDashboardFeature$ | async) || (hasPartnerDashboardFeature$ | async))) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-partner-dashboard"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'partner-dashboard'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Dashboard' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>dashboard</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Dashboard</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>dashboard</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Dashboard' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }

                            <!-- Tasks -->
                            @if (hasSmartTasksFeature$ | async) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'tasks'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Tasks' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>task_alt</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Tasks</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>task_alt</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Tasks' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }

                            <!-- Businesses -->
                            @if ((currentUser$ | async)?.isLender() && (hasBusinessListFeature$ | async)) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'businesses'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Businesses' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>store</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Businesses</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>store</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Businesses' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }
                            <!-- Funnel -->
                            @if ((currentUser$ | async)?.isLender()) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-apps"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   id="funnelV2NavLink"
                                   [routerLink]="'funnel'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Funnel V2' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>filter_alt</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Funnel</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>filter_alt</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>Funnel</span>
                                        }
                                    }
                                </a>
                            }

                            <!-- Reports -->
                            @if (hasReportsListFeature$ | async) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-reports"
                                   routerLinkActive
                                   id="reportsNavItem"
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'/reports'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Reports' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>insert_chart</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Reports</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>insert_chart</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Reports' | titlecase }}</span>

                                        }
                                    }
                                </a>
                            }

                            <!-- Marketing -->
                            @if ((currentUser$ | async)?.isLender() && (currentUser$ | async)?.permittedTo('lpxCanSeeMarketingModule')) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-marketing"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'/marketing'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Marketing' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>chat_filled</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Marketing</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>chat_filled</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'marketing' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }

                            <!-- Clients -->
                            @if (((currentUser$ | async)?.isReferralPartner()) && !(hasPartnerDashboardFeature$ | async) && !(hasAffiliateDashboardFeature$ | async)) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-clients"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'/clients'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Clients' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                            ? 'material-icons'
                                            : 'material-icons-outlined'">
                                                <span>file_copy</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Clients</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>file_copy</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Clients' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }

                            <!-- Forgiveness -->
                            <!-- @if ((currentUser$ | async)?.permissions?.canUploadLoanForgivenessCSV || (currentUser$ | async)?.permittedTo('canManageLoanForgivenessCSV')) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-forgive"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'/csv/forgive'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Forgiveness' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    <mat-icon [fontSet]="activeComponent.isActive
                                                ? 'material-icons'
                                                : 'material-icons-outlined'">
                                        <span>cloud_upload</span>
                                    </mat-icon>
                                    @if (lendioSidenav.opened) {
                                        <span>{{ 'Forgiveness' | titlecase }}</span>
                                    }
                                </a>
                            } -->

                            <!-- Administration -->
                            @if (adminVisible$ | async) {
                                <a mat-list-item
                                   class="lendio-nav-link"
                                   data-cy="nav-administration"
                                   routerLinkActive
                                   #activeComponent="routerLinkActive"
                                   [routerLink]="'/administration'"
                                   [class.is-active]="activeComponent.isActive"
                                   [class.collapsed]="!lendioSidenav.opened"
                                   [attr.tabindex]="activeComponent.isActive ? 0 : -1"
                                   [matTooltip]="!lendioSidenav.opened ? 'Administration' : ''"
                                   matTooltipPosition="after"
                                   (click)="forceLinkActive(activeComponent)">
                                    @if (navV2()) {
                                        <div class="lendio-nav-item-state-layer">
                                            <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                        ? 'material-icons'
                                                        : 'material-icons-outlined'">
                                                <span>admin_panel_settings</span>
                                            </mat-icon>
                                        </div>
                                        <span class="lendio-nav-item-name">Admin</span>
                                    } @else {
                                        <mat-icon [fontSet]="navLinkIsActive(activeComponent)
                                                    ? 'material-icons'
                                                    : 'material-icons-outlined'">
                                            <span>admin_panel_settings</span>
                                        </mat-icon>
                                        @if (lendioSidenav.opened) {
                                            <span>{{ 'Administration' | titlecase }}</span>
                                        }
                                    }
                                </a>
                            }
                        </mat-nav-list>
                            @if (navV2()) {
                                <mat-nav-list class="nav-support nav-v2">
                                    @if ((currentUser$ | async)?.isLender()) {
                                        <app-notification-icon-smart></app-notification-icon-smart>
                                    }
                                    <!-- Support -->
                                    @if ((currentUser$ | async)?.permittedTo('viewProductSupportChat')) {
                                        <a mat-list-item
                                           class="no-label-list-item"
                                           (click)="openZenDesk()">
                                            <div class="lendio-nav-item-state-layer">
                                                <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
                                            </div>
                                        </a>
                                    }
                                </mat-nav-list>
                            } @else {
                                <mat-nav-list class="nav-bottom">
                                    <!-- Support -->
                                    @if ((currentUser$ | async)?.permittedTo('viewProductSupportChat')) {
                                        <a mat-list-item
                                           class="support"
                                           (click)="openZenDesk()"
                                           [class.collapsed]="!lendioSidenav.opened"
                                           [class.nav-bottom-extended]="lendioSidenav.opened"
                                           [class.nav-bottom-collapsed]="!lendioSidenav.opened && !isSmallOrExtraSmall"
                                           [matTooltip]="!lendioSidenav.opened ? 'Help & Support' : ''"
                                           matTooltipPosition="after">
                                            <mat-icon fontSet="material-icons-outlined">help_outline</mat-icon>
                                            @if (lendioSidenav.opened) {
                                                <span>{{ 'Support & Help' | titlecase }}</span>
                                            }
                                        </a>
                                    }
                                </mat-nav-list>

                                <!-- Expand button -->
                                @if (!isSmallOrExtraSmall && !lendioSidenav.opened) {
                                    <button mat-icon-button
                                            class="button-nav-size nav-expand"
                                            (click)="lendioSidenav.open()">
                                        <mat-icon class="nav-expand-icon"
                                                  fontSet="material-icons-outlined">
                                            <span>start</span>
                                        </mat-icon>
                                    </button>
                                }

                                <!-- User -->
                                <div class="lendio-sidenav-user overflow-x-hidden">
                                    <app-sidenav-user
                                        data-cy="nav-user-menu"
                                        [userFullName]="userFullName"
                                        [organizationName]="organizationName"
                                        [collapsed]="!lendioSidenav.opened"
                                        (userLogout)="logout()"
                                        (userSettings)="goToSettings()" />
                                </div>
                            }
                    </mat-sidenav>
                    <mat-sidenav-content class="lendio-sidenav-content">
                        <mat-drawer-container class="h-full" autosize>
                            <!-- Header -->
                            <mat-toolbar data-cy="toolbar"
                                         class="lendio-toolbar"
                                         [class.hidden]="shouldHideToolbar$ | async"
                                         [class.border-visible]="shouldApplyToolbarBottomBorder(pageTitleData().title)"
                                         [class.nav-V2]="navV2()">
                                <mat-toolbar-row class="lendio-toolbar-layout" [class.nav-v2]="navV2()">
                                    <div class="lendio-toolbar-data">
                                        <div class="flex flex-col gap-1">
                                            <app-breadcrumb></app-breadcrumb>
                                            <div class="flex items-baseline gap-2">
                                                @if (pageTitleData().href) {
                                                    <a class="page-title page-title-anchor"
                                                       [routerLink]="[pageTitleData().href]"
                                                       matTooltip="View business"
                                                       matTooltipPosition="above"
                                                       matTooltipShowDelay="0">
                                                        {{ pageTitleData().title }}
                                                    </a>
                                                } @else {
                                                    <div class="page-title">
                                                        {{ pageTitleData().title }}
                                                    </div>
                                                }
                                                @if (pageTitleData().secondaryTitle) {
                                                    <span class="text-lendio-warm-gray-400 mat-subtitle-2">
                                                        {{ pageTitleData().secondaryTitle }}
                                                    </span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <span class="lendio-toolbar-spacer"></span>
                                    <div class="lendio-toolbar-actions">
                                        <!-- Notifications -->
                                        @if ((currentUser$ | async)?.isLender() && !navV2()) {
                                            <app-notification-icon-smart></app-notification-icon-smart>
                                        }
                                        <!-- Hijack -->
                                        @if ((currentUser$ | async)?.permissions?.canHijack) {
                                            <app-hijack-smart></app-hijack-smart>
                                        }

                                        <!-- Search -->
                                        @if ((currentUser$ | async)?.isLender() && (currentUser$ | async)?.institution?.id !== 44566) {
                                            <div class="invisible lg:!visible">
                                                @if ( newBusinessSearch() ) {
                                                    <search-input></search-input>
                                                } @else if (!navV2()) {
                                                    <app-applications-search></app-applications-search>
                                                }
                                            </div>
                                        }
                                    </div>
                                </mat-toolbar-row>
                            </mat-toolbar>
                            <!-- Alerts -->
                            @if (alerts) {
                                <div class="toaster-alerts"
                                     [class.active]="alerts.length > 0">
                                    @for (alert of alerts; track alert) {
                                        <app-alert [level]="alert.level"
                                                   [message]="alert.message"
                                                   [dismissible]="true"
                                                   (dismissed)="dismissAlert(alert)">
                                        </app-alert>
                                    }
                                </div>
                            }
                            <div class="module-router-view">
                                <!-- Router outlet -->
                                @if (isForbidden) {
                                    <app-not-found></app-not-found>
                                } @else {
                                    <router-outlet></router-outlet>
                                }
                            </div>
                            <!-- Notifications drawer -->
                            <mat-drawer class="mat-drawer--notifications"
                                        #notificationsidenav
                                        opened="{{ notificationSideNavExpanded }}"
                                        position="end"
                                        mode="side">
                                <app-notification-sidenav-smart></app-notification-sidenav-smart>
                            </mat-drawer>
                        </mat-drawer-container>
                    </mat-sidenav-content>
                </mat-sidenav-container>
                @if (navV2() && !globalSearchVisible) {
                    <div class="sidenav-user-v2">
                        <app-sidenav-user data-cy="nav-user-menu"
                                          [userFullName]="userFullName"
                                          [organizationName]="organizationName"
                                          (userLogout)="logout()"
                                          (userSettings)="goToSettings()" />
                    </div>
                }
            } @else {
                <ng-template [ngTemplateOutlet]="FeaturesLoading"></ng-template>
            }
        } @else {
            <div class="login-page">
                <router-outlet></router-outlet>
            </div>
        }
    </span>
}
