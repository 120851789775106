import { Component, OnInit, Input, OnDestroy, inject } from '@angular/core';
import { OffersService } from '../../../../services/offers.service';
import { Approval } from '../../../../interfaces/approval/approval.model';
import { take, takeUntil } from 'rxjs/operators';
import { ApprovalAdjustment } from '../../../../interfaces/approval/approval-adjustment.model';
import { Subject, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GetApprovals, GetApprovalCalculatorStorage, PublishApproval, UnpublishApproval } from '../../../../store/offers/approvals.actions';
import { ApprovalsState } from '../../../../store/offers/approvals.state';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-approval-smart',
  templateUrl: './approval-smart.component.html',
  styleUrls: ['./approval-smart.component.scss'],
  viewProviders: [MatExpansionPanel],
  standalone: false
})
export class ApprovalSmartComponent implements OnInit, OnDestroy {
  loading: {[key: string]: any} = {};
  errored: {[key: string]: any} = {};
  calcChannels: {[key: string]: any} = {};
  private destroyed$ = new Subject<void>();

  @Input() dealId: number;
  @Input() customControlsDisabled = false;
  @Input() expandIfSolo = false;
  @Input() readOnly: boolean = false;
  @Input() useStoreApprovals: boolean = false;
  approvalsLoading$: Observable<boolean> = this.store.select(ApprovalsState.loading);
  approvals$: Observable<Approval[] | null> = this.store.select(ApprovalsState.approvals);
  approvals: Approval[] | null;
  locTermSbaApprovals: Approval[] | null;
  otherApprovals: Approval[] | null;
  
  emptyType = 'approvals'
  emptyLabelContent = 'There are no approvals.'
  
  private ngUnsubscribe = new Subject<boolean>();
  
  // Permissions
  canPublish$: Observable<boolean> = this.store.select(AuthState.userHasPermission('lpxPublishOption'));

  constructor(
    private offersService: OffersService,
    private store: Store
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
      if(this.useStoreApprovals) {
        this.store.dispatch(new GetApprovals(this.dealId));
        this.approvals$.pipe(takeUntil(this.destroyed$)).subscribe((approvals) => {
          this.approvals = approvals;

          // Splitting approvals into two groups:
          //    1. loc/term/sba
          //    2. ach and other
          //
          // This is so we can pass the loc/term approvals to the new-theme
          // version of the approval component (app-approvals)
          //
          // The ach and other approvals will be passed to the bastard component
          // that was built to quickly port the ach slider calculator thing
          // into the new theme (app-ach-approvals)
          //
          // Ultimately, the ideal would be to make app-approvals smart enough to
          // handle ach approvals
          this.locTermSbaApprovals = approvals?.filter(x => {
            const type = x.calculationType.toLocaleLowerCase();
            return type === 'loc' || type === 'term' || type === 'sba'
          }) ?? [];
          this.otherApprovals = approvals?.filter(x => {
            const type = x.calculationType.toLocaleLowerCase();
            return type !== 'loc' && type !== 'term' && type !== 'sba'
          }) ?? [];
        });
      }
      this.store.dispatch(new GetApprovalCalculatorStorage());
  }

  handleAdjustedApproval({ amount, points, term, frequency, origination, approvalId }: ApprovalAdjustment) {
    this.loading[approvalId] = true; // change to object structure w/ approval id

    return this.offersService
      .getAdjustedApproval(approvalId, amount, term, points, frequency, origination)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          if (!res || !res.channel) {
            this.errored[approvalId] = true;
            // Should test this out and provide an error state if this ever actually happens
            return;
          }

          this.calcChannels[approvalId] = res.channel;
        },
        error: (res) => {
          this.loading[approvalId] = false;
          this.errored[approvalId] = true;
        },
      });
  }

  getLoading(approvalId: number) {
    if (!this.loading[approvalId]) {
      return false;
    }

    return this.loading[approvalId];
  }

  getErrored(approvalId: number) {
    if (!this.errored[approvalId]) {
      return false;
    }

    return this.errored[approvalId];
  }

  setErrored(approvalId: number) {
    this.errored[approvalId] = true;
  }

  getCalcChannel(approvalId: number) {
    if (!this.calcChannels[approvalId]) {
      return null;
    }

    return this.calcChannels[approvalId];
  }

  handlePusherPayloadReceived(approvalId: number): void {
    this.loading[approvalId] = false;
  }
  
  togglePublished([setStateTo, approvalId]: [boolean, number]) {
    if (setStateTo === true) {
      this.store.dispatch(new PublishApproval(approvalId));
    } else {
      this.store.dispatch(new UnpublishApproval(approvalId));
    }
  }
}
