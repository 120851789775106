@if (offers$ | async; as offers) {
    <app-offers [offers]="offers"
                [sbaLoanNumber]="sbaLoanNumber"
                [dealId]="dealId"
                [loanProductCategory]="loanProductCategory"
                [tenantId]="tenantId"
                [canPublishOffers]="canPublishOffers$ | async"
                (toggledPublished)="togglePublished($event)"
                />
} @else {
    <app-dot-loader-scale />
}
