<!-- Business/borrower status component available when we have a better place
to put it. Deal status is in the right side drawer. -->
<!-- <div *ngIf="business$ | async as b">
    <app-borrower-status-indicator [stage]="b.stage" [status]="b.status"></app-borrower-status-indicator>
</div> -->

<!-- Tab content -->
<mat-tab-group [attr.data-cy]="'business-details-tabs'"
               [class.nav-v2]="navV2()"
               mat-stretch-tabs="false"
               animationDuration="0ms"
>
    <!-- Overview tab -->
    <mat-tab [attr.data-cy]="'overview-tab'" (click)="setTab('overview')">
        <div class="grid grid-flow-col grid-cols-[1fr_334px]">
            <ng-template mat-tab-label>
                    <span>
                        Overview
                    </span>
            </ng-template>
            <ng-template matTabContent>
                <app-overview [id]="(business$ | async)?.id"></app-overview>
            </ng-template>
        </div>
    </mat-tab>

    <!-- Contacts tab -->
    <mat-tab [attr.data-cy]="'contacts-tab'" (click)="setTab('contacts')">
        <div class="grid grid-flow-col grid-cols-[1fr_334px]">
            <ng-template mat-tab-label>
                    <span>
                        Contacts <span *ngIf="(contacts$ | async) as contacts" class="!font-light !text-gray-400">&nbsp;{{contacts?.length}}</span>
                    </span>
            </ng-template>
            <ng-template matTabContent>
                <app-contacts [id]="(business$ | async)?.id"></app-contacts>
            </ng-template>
        </div>
    </mat-tab>

    <!-- Finances tab -->
    <mat-tab [attr.data-cy]="'finances-tab'" *ngIf="canViewFinancesTab$ | async" (click)="setTab('finances')">
        <div class="grid grid-flow-col grid-cols-[1fr_334px]">
            <ng-template mat-tab-label>
                    Finances
            </ng-template>
            <ng-template matTabContent>
                <app-finances-page></app-finances-page>
            </ng-template>
        </div>
    </mat-tab>

    <!-- Documents tab -->
    <mat-tab [attr.data-cy]="'documents-tab'" (click)="setTab('documents')">
        <div class="grid grid-flow-col grid-cols-[1fr_334px]">
            <ng-template mat-tab-label>
                    <span>
                        Documents <span *ngIf="(documents$ | async) as documents" class="!font-light !text-gray-400">&nbsp;{{documents?.length}}</span>
                    </span>
            </ng-template>
            <ng-template matTabContent>
                <div class="grid grid-flow-col">
                    <div class="p-4">
                        <mat-card appearance="outlined">
                            <mat-card-content>
                                <app-documents-smart
                                    [borrowerId]="(business$ | async)?.id"
                                    [borrowerLenderId]="businessLenderId$ | async"
                                    [canSeeAdvDocManagement]="canSeeAdvDocMgmt()"
                                    [attr.data-cy]="'app-documents'"
                                />
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </ng-template>
        </div>
    </mat-tab>

    <!-- Deals tab -->
    <mat-tab [attr.data-cy]="'deals-tab'" (click)="setTab('deals')">
        <div class="grid grid-flow-col grid-cols-[1fr_334px]">
            <ng-template mat-tab-label>
                <span>
                    Deals <span *ngIf="(business$ | async) as business" class="!font-light !text-gray-400">&nbsp;{{business.deals?.length}}</span>
                </span>
            </ng-template>
            <ng-template matTabContent>
                <div id="business-deals" class="p-4" *ngIf="(business$ | async) as business">
                    <div *ngIf="business.deals && business.deals.length else empty">
                        <app-deal-simple *ngFor="let deal of business.deals"
                            [deal]="deal">
                        </app-deal-simple>
                    </div>
                </div>
            </ng-template>
        </div>
    </mat-tab>

    @if (canEditDscr() && business$ | async; as business) {

        <mat-tab>
            <ng-template mat-tab-label>DSCR</ng-template>
            <ng-template matTabContent>
                <div appDynamicScreenHeight [dshdConfig]="{scrollable: true}">
                    <app-dscr-tab [businessId]="business.id"></app-dscr-tab>
                </div>
            </ng-template>
        </mat-tab>
    }


    <!-- Loading -->
    <div class="overlay-loader m-auto" *ngIf="loading$ | async;">
        <app-dot-loader-scale></app-dot-loader-scale>
    </div>
</mat-tab-group>

<ng-template #empty>
    <mat-card class="m-4" appearance="outlined">
        <mat-card-content>
            <app-empty-state [type]="emptyType" [label]="emptyLabelContent">
              <div class="text-center font-normal text-gray-900 mt-2" messageContent>{{emptyMessageContent}}</div>
            </app-empty-state>
        </mat-card-content>
    </mat-card>
</ng-template>
