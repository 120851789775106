<div data-cy="document-requirements-dialog">
    <div class="flex justify-between content-center">
        <div class="mat-title-large !pb-4 pt-6 pl-6" data-cy="document-requirements-dialog-header">
            Add requirement
        </div>
        <button (click)="cancel($event)" class="pr-6">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="px-6">
        After adding requirements you can choose to notify the borrower that additional documents are needed.
    </div>
    <mat-dialog-content>
        <div class="mat-label-medium mb-2">
            Requirements
        </div>
        <document-requirement-list-item *ngFor="let category of selectedCategories; index as i; trackBy trackByCategory"
                                        data-cy="document-requirement-list-item"
                                        (onTimeClassification)="handleTimeClassification($event)" 
                                        (onRemove)="handleRequirementRemoval($event)"
                                        [documentRequirement]="category"
                                        [index]="i">
        </document-requirement-list-item>
        <searchable-select [items]="categories"
                           [optionValueKey]="'name'"
                           [searchable]="true"
                           [expanded]="selectExpanded"
                           (onApply)="closeSelectExpansion($event)"
                           (onCancel)="closeSelectExpansion($event)"
        >
        <button data-cy="add-document-requirement-dialog-button"
                class="text-lendio-blue-400 flex items-center mt-2"
                (click)="openSelectExpansion()"
                searchableSelectTrigger
        >
            <mat-icon class="leading-none mr-2">add</mat-icon>
            <span class="font-semibold">Add</span>
        </button>
        </searchable-select>
        
        <!-- Only show Notify borrower section if there are doc types we are allowed to notify about -->
        @if (!onlyBorrowerRestrictedCategoriesSelected()) {
            <div class="notify-borrower-container mt-4">
                <div class="flex justify-between items-center">
                    <div class="mat-label-medium">
                        Notify borrower
                    </div>
                    <mat-slide-toggle disableRipple
                                      data-cy="notify-borrower-toggle"
                                      name="notifyBorrower"
                                      id="notice-to-borrower-toggle"
                                      [checked]="notifyBorrower"
                                      (change)="notifyBorrower = $event.checked">
                    </mat-slide-toggle>
                </div>
                <mat-accordion [displayMode]="'flat'" class="notify-borrower borderless mat-elevation-z0">
                    <mat-expansion-panel class="mat-elevation-z0 p-0" [expanded]="notifyBorrower" hideToggle>
                        <quill-editor
                            data-cy="note-to-borrower"
                            class="w-full pt-6"
                            [formControl]="quillFormControl"
                            [modules]="quillModules"
                            placeholder="Special instructions&hellip;"
                        />
                        <!-- Validation error placeholder -->
                        <div class="h-6">
                            @if (quillFormControl.hasError('minlength')) {
                                <mat-error>
                                    Note must be at least 10 characters long.
                                </mat-error>
                            }
                            @if (quillFormControl.hasError('maxlength')) {
                                <mat-error>
                                    Content exceeds maximum size of 25MB.
                                </mat-error>
                            }
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        }
    </mat-dialog-content>
    <div id="spacer" class="w-full h-[8px]"></div>
    <mat-dialog-actions class="z-10" align="end">
        <button mat-button class="!text-base" color="primary" (click)="cancel($event)">
            Cancel
        </button>
        <button mat-flat-button color="primary" class="!ml-3 !text-base"
            data-cy="document-requirements-save-btn"
            (click)="save()"
            [disabled]="shouldDisableSaveButton()"
        >
            <span class="mx-2">Save</span>
        </button>
    </mat-dialog-actions>
</div>
