@if (cleanedFields.length) {
	<div data-cy="mp-application-details">
		<h4 class="table-pipeline-header">Application details</h4>
		<div class="row">
			<div class="col">
				<table class="table table-pipeline">
					<tr class="table-row" *ngFor="let item of cleanedFields">
						<td class="table-pipeline-item">{{ item.name || item.shortName || item.alias }}</td>
						<td id="{{item.alias}}" [ngSwitch]="item.displayType" class="table-pipeline-value">
							<span *ngSwitchCase="'currency'">
								{{ item.value | currency }}
							</span>
							<span *ngSwitchCase="'bool'">
								<span *ngIf="item.value == 0">No</span>
								<span *ngIf="item.value == 1">Yes</span>
							</span>
							<span *ngSwitchCase="'number'">
								{{ item.value | number }}
							</span>
							<span *ngSwitchDefault>
								{{ item.value }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
}