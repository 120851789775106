import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { DealsService } from '@app/app/services/deals.service';
import { GetDealActivities } from '@app/app/store/deal-activities/deal-activities.actions';
import { catchError, tap } from 'rxjs/operators';
import { CreateNewAlert } from '@app/app/store/global-alerts/global-alerts.actions';
import { throwError } from 'rxjs';
import { isBefore, subMinutes } from 'date-fns';


export class DealActivitiesStateModel {
  dealId: number | null;
  activities: DealActivity[];
  loading: boolean;
  lastFetched?: Date;
}

@State<DealActivitiesStateModel>({
  name: 'dealActivities',
  defaults: {
    dealId: null,
    activities: [],
    loading: false,
  }
})
@Injectable()
export class DealActivitiesState {

  @Selector()
  static dealId( state: DealActivitiesStateModel ) {
    return state.dealId;
  }

  @Selector()
  static activities( state: DealActivitiesStateModel ) {
    return state.activities;
  }

  @Selector()
  static loading( state: DealActivitiesStateModel ) {
    return state.loading;
  }

  constructor(
    private dealsService: DealsService,
    private store: Store,
  ) {}
@Action( GetDealActivities )
  getDealActivities(
    ctx: StateContext<DealActivitiesStateModel>,
    { dealId }: GetDealActivities
  ) {
    const current = ctx.getState();
    const dataStale = !current.lastFetched || isBefore(current.lastFetched, subMinutes(new Date(Date.now()), 5));

    if(dealId && (dealId !== current.dealId || dataStale)) {
      ctx.patchState({
        dealId,
        loading: true,
      });

      return this.dealsService.getDealActivities(dealId).pipe(
        catchError( ( err ) => {
          this.store.dispatch( new CreateNewAlert( {
            level: 'error',
            message: 'Unable to fetch Deal Activities'
          } ) );
          ctx.patchState( {
            loading: false,
          } );
          return throwError( err );
        } ),
        tap( response => {
          ctx.patchState({
            activities: response.data,
            loading: false,
            lastFetched: new Date(Date.now()),
          });
        })
      );
    }
  }
}
