import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractRequest } from '@app/app/interfaces/contract-request.model';
import { LendioResponse, ObsLendioResponse } from '@app/app/interfaces/lendio-response';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContractRequestsService
{
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getDealContractRequest(dealId: number): ObsLendioResponse<ContractRequest> {
        return this.http.get<LendioResponse<ContractRequest>>(`${this.apiUrl}/l/v2/internal/deals/${dealId}/contract-requests-lender`);
    }

    createPreviewContractRequest(dealId: number): ObsLendioResponse<ContractRequest> {
      return this.http.post<LendioResponse<ContractRequest>>(`${this.apiUrl}/partner-portal/deals/${dealId}/preview-create-contract-request`, {});
    }
}
