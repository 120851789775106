export class GetContractRequestOffers {
    static readonly type = '[ContractRequests] GetContractRequestOffers';
    constructor(public dealId: number) {}
}

export class ClearContractRequestStore {
    static readonly type = '[ContractRequests] ClearContractRequestStore';
    constructor() {}
}

export class CreatePreviewContractRequest {
  static readonly type = '[ContractRequests] CreatePreviewContractRequest';
  constructor(public dealId: number) {}
}
