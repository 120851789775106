@if( contractRequestsLoading() ) {
    <app-dot-loader-scale class="!my-0 !mx-auto"></app-dot-loader-scale>
} @else {
    @if( dealContractRequest() ) {
        <app-contract-requests [contractRequest]="dealContractRequest()"
                               [loanProductName]="loanProductName"
                               [dealId]="dealId"
        />
    } @else {
        <mat-card class="mat-mdc-card-content m-4" appearance="outlined">
            <app-empty-state [type]="'offers-contracts'" [label]="'There is no contract request.'" />
        </mat-card>
    }
}
