export class GetDealOffers {
    static readonly type = '[Offers] GetDealOffers';
    constructor(public dealId: number) {}
}

export class CreateOffer {
    static readonly type = '[Offers] CreateOffer';
    constructor(public dealId: number, public packagedOffer: any) {}
}

export class CreateAndAcceptOffer {
    static readonly type = '[Offers] CreateAndAcceptOffer';
    constructor(public dealId: number, public packagedOffer: any) {}
}

export class AcceptOffer {
    static readonly type = '[Offers] AcceptOffer';
    constructor(public offerId: number) {}
}

export class CreatePPPOffer {
    static readonly type = '[Offers] CreatePPPOffer';
    constructor(public dealId: number, public packagedOffer: any) {}
}

export class ClearOffersStore {
    static readonly type = '[Offers] ClearOffersStore';
    constructor() {}
}

export class PublishOffer {
	static readonly type = '[Offers] PublishOffer';
	constructor(
		public offerId: number,
	) {}
}

export class UnpublishOffer {
	static readonly type = '[Offers] UnpublishOffer';
	constructor(
		public offerId: number,
	) {}
}
