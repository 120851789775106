@if ((breadCrumbs$ | async)?.length) {
    <div class="bread-crumb-container">
        @for (breadcrumb of (breadCrumbs$ | async); track breadcrumb) {
                <a class="breadcrumb-link" [routerLink]="breadcrumb.path">
                    {{ breadcrumb.label }}
                </a>
                <span class="bread-crumb-next material-symbols-outlined">navigate_next</span>
        }
    </div>
}

