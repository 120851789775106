import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap, throwError } from 'rxjs';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import {
  ClearFunnelDealsStore,
  GetFunnelDeals,
  StopFunnelDealsRequests,
} from './funnel-deals.actions';
import { FunnelDealsService } from '@app/app/services/funnel-deals.service';
import { FunnelDeal } from '@app/app/interfaces/funnel-deal.model';

export class FunnelDealsStateModel {
  deals: FunnelDeal[];
  retrievingDeals: boolean;
  allDealsRetrieved: boolean;
  shouldStopFunnelDealsRequests: boolean;
  intialLoadingComplete: boolean;
}

@State<FunnelDealsStateModel>({
  name: 'funnelDeals',
  defaults: {
    deals: [],
    retrievingDeals: false,
    allDealsRetrieved: false,
    shouldStopFunnelDealsRequests: false,
    intialLoadingComplete: false,
  },
})
@Injectable()
export class FunnelDealsState {
  private _lastLimit: number;
  private _lastOffset: number;

  @Selector()
  static deals(state: FunnelDealsStateModel) {
    return state.deals;
  }

  @Selector()
  static initialLoadingComplete(state: FunnelDealsStateModel) {
    return state.intialLoadingComplete;
  }

  constructor(
    private _funnelDealsService: FunnelDealsService,
    private _store: Store
  ) {}

  @Action(GetFunnelDeals)
  getFunnelDeals(
    { patchState, getState }: StateContext<FunnelDealsStateModel>,
    { params }: GetFunnelDeals
  ) {
    // Deals already loaded
    if (getState().allDealsRetrieved) {
      return;
    }

    // We’re still loading deals from a previous fresh load of the Funnel.
    if (params.isInitialRequest && getState().retrievingDeals) {
      return;
    }

    // Reset flag to stop requests if this is a fresh load of the Funnel.
    if (params.isInitialRequest && getState().shouldStopFunnelDealsRequests) {
      patchState({ shouldStopFunnelDealsRequests: false });
    }

    if (getState().shouldStopFunnelDealsRequests) {
      this._store.dispatch(new ClearFunnelDealsStore());
      return;
    }

    patchState({ retrievingDeals: true });

    this._lastLimit = params.limit;
    this._lastOffset = params.offset;

    return this._funnelDealsService.getFunnelDeals(params).pipe(
      catchError((error) => {
        patchState({ intialLoadingComplete: true });
        this._store.dispatch(
          new CreateNewAlert({
            level: 'error',
            message: 'Could not retrieve funnel deals',
          })
        );
        return throwError(() => error);
      }),
      tap((response) => {
        patchState({
          deals: [...getState().deals, ...(response.data?.deals || [])],
          retrievingDeals: !response.data?.allDealsRetrieved,
          allDealsRetrieved: response.data?.allDealsRetrieved,
          intialLoadingComplete: true,
        });

        // If necessary, continue fetching deals in larger chunks
        if (!response.data?.allDealsRetrieved) {
          this._store.dispatch(
            new GetFunnelDeals({
              limit: 10000,
              offset: this._lastOffset + this._lastLimit,
              checkAan: params.checkAan,
              isInitialRequest: false,
            })
          );
        }
      })
    );
  }

  @Action(StopFunnelDealsRequests)
  stopFunnelDealsRequests({ patchState }: StateContext<FunnelDealsStateModel>) {
    patchState({ shouldStopFunnelDealsRequests: true });
  }

  @Action(ClearFunnelDealsStore)
  ClearFunnelDealsState(
    { patchState }: StateContext<FunnelDealsStateModel>,
    {}: ClearFunnelDealsStore
  ) {
    patchState({ deals: [], allDealsRetrieved: false, retrievingDeals: false });
  }
}
