@if (emptyState()) {
    <app-empty-state type="offers-contracts" label="There are no messages.">
        <div class="text-center font-normal text-gray-900 mt-2" messageContent>
            Please <a class="text-blue-500 hover:text-blue-800 cursor-pointer" data-cy="fd-request-btn" (click)="addAMessage()">add a message</a> to get going.
        </div>
    </app-empty-state>
} @else {
    <div class="fd-container lendio-density-1"
         data-cy="contact-funding-desk">
        <div class="flex justify-start pb-1 flex-no-wrap whitespace-no-wrap">
            @if(!expandStatus) {
                <button mat-button color="primary" class="pull-righcursor-pointer " data-cy="fd-request-btn" (click)="fdPanel.open()">
                    <i class="fa fa-plus" aria-hidden="true"></i> New
                </button>
            }
        </div>

        <mat-expansion-panel
            hideToggle="true"
            class="mat-elevation-z0 fd-request messages-tab"
            [(expanded)]="expandStatus"
            #fdPanel
            (afterExpand)="handleAfterExpand()"
        >
            <h3 class="!m-0">New message</h3>
            @if(expandStatus) {
                <mat-form-field appearance="fill" class="w-full !my-4 lendio-density-2">
                    <textarea
                        class="!resize-none"
                        matInput
                        appTextareaAutoresize
                        data-cy="fd-request-input"
                        placeholder="What can we help you with?"
                        [(ngModel)]="newRequest"
                        [ngModelOptions]="{standalone: true}"
                        #requestTextArea
                    ></textarea>
                </mat-form-field>
            }

            <div>
                <div class="pull-right">
                    <button mat-button color="primary" (click)="cancelComment()">Cancel</button>
                    <button
                        mat-flat-button
                        color="primary"
                        type="button"
                        data-cy="fd-request-submit-btn"
                        [disabled]="newRequest === ''"
                        (click)="createNewRequest(newRequest)"
                        class="!ml-3"
                    >Send</button>
                </div>
            </div>

        </mat-expansion-panel>

        <div *ngIf="fundingDeskRequests && fundingDeskRequests.length > 0" class="requests">
            <app-single-request-chain
                *ngFor="let request of fundingDeskRequests; let i = index"
                [requestChain]="request"
                [requestIndex]="i"
                (comment)="addNewComment($event)"
            ></app-single-request-chain>
        </div>
    </div>
}
