import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, Signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentRequirementItem } from '@app/app/interfaces/document-requirement-item.model';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { Store } from '@ngxs/store';

@Component({
    selector: 'document-requirement-list-item',
    standalone: true,
    templateUrl: './document-requirement-list-item.component.html',
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule
    ]
})
export class DocumentRequirementListItem {
    @Input() documentRequirement: DocumentRequirementItem;
    @Output() onTimeClassification = new EventEmitter<{ documentRequirementItem: DocumentRequirementItem, index: number }>();
    @Output() onRemove = new EventEmitter<number>();
    @Input() index: number;
    timeClassificationOptions: Signal<{[key: string]: { display: string, value: string, parts: { month: number, year: number } } | string}>;

    constructor(store: Store) {
        this.timeClassificationOptions = store.selectSignal(DocumentsState.remainingTimeOptionsByRequiredDocumentCategory);
    }

    updateMonthString({ value }) {
        this.onTimeClassification.emit({ documentRequirementItem: { ...this.documentRequirement, monthValues: value }, index: this.index });
    }

    updateYearString({ value }) {
        this.onTimeClassification.emit({ documentRequirementItem: { ...this.documentRequirement, yearsString: value }, index: this.index });
    }

    remove() {
        this.onRemove.emit(this.index);
    }
}
