@if (navV2()) {
    <div class="sidenav-search-view-results lendio-density-1">
        <mat-form-field class="sidenav-search-view-input"
                        appearance="fill">
            <mat-icon matPrefix class="sidenav-search-view-icon">search</mat-icon>
            <input matInput
                   type="text"
                   placeholder="Search..."
                   aria-label="Input"
                   [formControl]="searchControl">
            <div class="lendio-density-2" matSuffix>
                <button mat-icon-button
                        class="sidenav-search-view-close-button"
                        (click)="handleInFieldClearButton()">
                    <mat-icon class="sidenav-search-view-close-icon">close</mat-icon>
                </button>
            </div>
        </mat-form-field>
        @for (result of results$ | async; track result) {
            <button class="sidenav-search-view-option"
                    (click)="handleResultSelected(result.resourceId)">
                <div class="flex flex-row justify-between items-center">
                    <div class="sidenav-search-view-option-name">{{ result?.title }}</div>
                    <!--
                    todo - status is not part of the payload yet but these styles are ready to use in the theme
                    @if (result.deal?.status) {
                        <div class="sidenav-search-view-status-display">
                            <div class="sidenav-search-view-status-indicator {{ result.deal?.status }}"></div>
                            <div class="sidenav-search-view-status">
                                {{ result?.deal?.status | kebabToSentence }}
                            </div>
                        </div>
                    }
                    -->
                </div>
                <div class="sidenav-search-view-option-description">{{ result?.subTitle }}</div>
            </button>
        } @empty {
            @if (searchHelpVisible) {
                <div class="sidenav-search-view-empty-state"
                     [appFadeAnimation]
                     [fadeOpacityPercent]="100"
                     [fadeEnterDurationInMs]="250"
                     [fadeEnterDelayInMs]="0"
                     [fadeLeaveDurationInMs]="250"
                     [fadeLeaveDelayInMs]="0">
                    We couldn’t find any Businesses or Deals matching your search.
                </div>

                <div class="sidenav-search-view-tips"
                     [appFadeAnimation]
                     [fadeOpacityPercent]="100"
                     [fadeEnterDurationInMs]="250"
                     [fadeEnterDelayInMs]="0"
                     [fadeLeaveDurationInMs]="250"
                     [fadeLeaveDelayInMs]="0">
                    <div class="sidenav-search-view-tips-header">
                        <mat-icon fontSet="material-icons-outlined">lightbulb_2</mat-icon>
                        Tips for better results:
                    </div>
                    <div class="sidenav-search-view-tip">
                        You can search by business name, deal name, or relevant keywords.
                    </div>
                    <div class="sidenav-search-view-tip">
                        Try using partial terms if you’re unsure of the full name.
                    </div>
                </div>
            }
        }
    </div>

} @else {



    <div class="lendio-density-2">
        <mat-form-field
            *appLet="(errored$ | async) as errored"
            appearance="outline">
            <mat-icon
                matPrefix
                [color]="errored ? 'warn' : ''"
                [matTooltip]="errored ? 'There was an error while attempting to search. Please try again.' : ''"
                matTooltipPosition="below">
                <span>{{ errored ? 'warning' : 'search' }}</span>
            </mat-icon>
            <input matInput
                   #searchInput
                   [(ngModel)]="searchTerm"
                   [matAutocomplete]="resultsAutocomplete"
                   (keyup)="handleKeyup($event)"
                   placeholder="Search businesses..."
                   autocomplete="off"
                   type="text"/>

            <div matSuffix class="w-6 mr-2">
                @if( loadingState$ | async ) {
                    <mat-spinner [diameter]="24"/>
                } @else {
                    <button mat-icon-button
                            *ngIf="searchTerm?.length > 1"
                            (click)="$event.stopPropagation(); $event.preventDefault(); resetSearch();">
                        <mat-icon [fontSet]="'material-icons-outlined'"
                                  [color]="'accent'">
                            cancel
                        </mat-icon>
                    </button>
                }
            </div>

            <mat-autocomplete
                #resultsAutocomplete="matAutocomplete"
                [hideSingleSelectionIndicator]="true"
                [class]="'!max-h-[456px] py-2'"
            >
                <!--    RECENT SEARCHES    -->
                <ng-container *ngIf="!searchTerm?.length && (showRecentSearchState$ | async)">
                    <div class="grid grid-flow-col justify-between place-items-center px-4 py-2 lendio-density-1">
                        <h2 class="!mb-0">Recent searches</h2>
                        <a class="mat-body-2 !text-lendio-blue-400 align-middle cursor-pointer"
                           (click)="$event.preventDefault(); $event.stopPropagation(); clearRecentSearches();">
                            Clear
                        </a>
                    </div>
                    <mat-option
                        *ngFor="let search of (recentSearches$ | async)"
                        (click)="handleRecentSearchClick(search.term);"
                    >
                        <div class="mat-body-2">{{ search.term }}</div>
                        <div class="mat-caption text-lendio-cool-gray-300">{{ search.timestamp | timeago }}</div>
                    </mat-option>
                </ng-container>

                <!-- RESULTS -->
                <mat-option
                    *ngFor="let result of (results$ | async)"
                    (click)="handleResultClick(result.resourceId)">
                    <div class="mat-body-2" [innerHTML]="result.title"></div>
                    <div *ngIf="result.subTitle" class="mat-caption text-lendio-cool-gray-300" [innerHTML]="result.subTitle"></div>
                </mat-option>

                <!-- No results -->
                <mat-option disabled *ngIf="(noResultsState$ | async) && !!searchTerm?.length">
                    No results for '{{ searchTerm }}'
                </mat-option>

            </mat-autocomplete>
        </mat-form-field>
    </div>
}
