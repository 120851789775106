import {
  Directive,
  AfterViewInit,
  ElementRef,
  HostListener,
  Renderer2,
  ContentChildren,
  QueryList,
  OnDestroy
} from '@angular/core';

@Directive({
  selector: '[GridTopBottomIdentifier]',
})
export class GridTopBottomIdentifier implements AfterViewInit, OnDestroy {
  @ContentChildren('DscrGridItem', { read: ElementRef }) gridItems: QueryList<ElementRef>;
  @HostListener('window:resize')
  onResize() {
    clearTimeout(this._resizeTimeout);
    this._resizeTimeout = setTimeout(() => this._identifyTopBottomItems(), 150);
  }

  private _resizeTimeout: any;

  constructor(
    private _el: ElementRef,
    private _renderer: Renderer2
  ) { }

  ngAfterViewInit(): void {
    this._identifyTopBottomItems();
  }

  ngOnDestroy(): void {
    clearTimeout(this._resizeTimeout);
  }

  private _identifyTopBottomItems(): void {
    const gridContainer = this._el?.nativeElement;
    const gridItems = this.gridItems.toArray().map(item => item?.nativeElement);

    gridItems.forEach(item => {
      this._renderer.removeClass(item, 'top-of-column');
      this._renderer.removeClass(item, 'bottom-of-column');
    });

    if (gridItems.length === 0) {
      return;
    }
    const gridComputedStyle = window.getComputedStyle(gridContainer);
    const gridColumns = gridComputedStyle.getPropertyValue('grid-template-columns').split(' ');
    const columnCount = gridColumns.length;
    const lastItemInColumn = new Array(columnCount).fill(-1);

    gridItems.forEach((item, index) => {
      const colIndex = index % columnCount;

      if (lastItemInColumn[colIndex] === -1) {
        this._renderer.addClass(item, 'top-of-column');
      }
      lastItemInColumn[colIndex] = index;
    });

    lastItemInColumn.forEach(lastIndex => {
      if (lastIndex !== -1) {
        this._renderer.addClass(gridItems[lastIndex], 'bottom-of-column');
      }
    });
  }
}
