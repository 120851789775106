@if (dscrLoading()) {
    <app-dot-loader-scale></app-dot-loader-scale>
} @else {
<div class="dscr-tab-view">
    <div class="dscr-view-swap">
        <div class="dscr-toggle-group">
            @for (year of years(); track $index; let first = $first; let last = $last) {
                <button class="dscr-view-toggle-button"
                        [class.active-view-year]="viewYear() === year"
                        [class.first]="first"
                        [class.last]="last"
                        (click)="selectYear(year)">
                    <span class="view-toggle-label">{{ year }}</span>
                </button>
            }
        </div>
    </div>
    @if (hasDscrYearData()) {
    <div class="dscr-year-template">
        <div class="dscr-year-view">
            <div class="dscr-year-view-top">
                <div class="dscr-title">Year: {{ viewYear() }}</div>
                <div class="lendio-density-1">
                    @if( canEditDscrData() ) {

                        <button mat-icon-button (click)="openEditDialog()">
                            <mat-icon fontSet="material-icons-outlined">edit_pencil</mat-icon>
                        </button>

                    }
                </div>
            </div>
            @if (businessTaxData()?.length) {
                <div class="dscr-year-section">
                    <div class="dscr-title-2">
                        @if (viewYear() === currentYear()) {
                           Business profit and loss (year to date)
                        } @else {
                            Business Tax Data
                        }
                    </div>
                    <div class="dscr-year-items" GridTopBottomIdentifier>
                        @for (item of businessTaxData(); track $index;) {
                            <div class="dscr-year-item-row" #DscrGridItem>
                                <div class="dscr-label">{{ item.label }}</div>
                                <div class="dscr-label value">
                                    {{ (item.profitLossStatementFieldValue | currency) || '-' }}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            @if (personalTaxData()?.length && viewYear() !== currentYear()) {
                <div class="dscr-year-section">
                    <div class="dscr-title-2">
                        Personal Tax Data
                    </div>
                    <div class="dscr-year-items" GridTopBottomIdentifier>
                        @for (item of personalTaxData(); track $index;) {
                            <div class="dscr-year-item-row" #DscrGridItem>
                                <div class="dscr-label">{{ item.label }}</div>
                                <div class="dscr-label value">
                                    {{ (item.profitLossStatementFieldValue | currency) || '-' }}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }

            @if( viewYear() === currentYear() ) {

                <div class="dscr-title-2">Debt schedule</div>
                <div class="dscr-year-section debt-schedule">
                    @if( debtScheduleItems()?.length ) {
                        @for (item of debtScheduleItemsData(); track item.id; let last = $last) {
                            <div class="dscr-year-debt-schedule-section" [class.last]="last">
                                <div class="column-grid justify-between place-items-center py-2">
                                    <div class="dscr-title-2">{{ item.lenderName }}</div>
                                    <div class="lendio-density-1">
                                        <button mat-icon-button (click)="editDebtScheduleItem(item.id)">
                                            <mat-icon fontSet="material-icons-outlined">edit_pencil</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="dscr-year-items debt-schedule-items" GridTopBottomIdentifier>
                                    @for (property of item | keyvalue; track property) {
                                        @if (property?.key && property.key !== 'lenderName' && property.key !== 'id') {
                                            <div class="dscr-year-item-row debt-schedule" #DscrGridItem>
                                                <div class="dscr-label">
                                                    {{ property.key.toString() | camelToSentence }}
                                                </div>
                                                <div class="dscr-label value">
                                                    {{ (property?.value?.toString()) || '-' }}
                                                </div>
                                            </div>
                                        }
                                    }
                                </div>
                            </div>
                        }
                    } @else {
                        <div class="dscr-title-2 empty-state"> No debt schedule items</div>
                    }
                    <div class="flex flex-row my-4 mx-6"
                         [ngClass]="debtScheduleItems()?.length ? 'justify-start' : 'justify-center'">
                        @if( canEditDscrData() ) {

                            <button mat-button
                                    (click)="newDebtScheduleItem()"
                                    color="primary">
                                <mat-icon matPrefix color="primary">add</mat-icon>
                                Add debt schedule item
                            </button>

                        }
                    </div>
                </div>

            }

        </div>
    </div>
    } @else {
        <div class="dscr-title-2 empty-state"> No DSCR data for {{ viewYear() }}</div>
    }
</div>
}
