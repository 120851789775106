import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { ApplicationsListService } from '../../../services/applications-list.service';
import { throttle } from 'lodash';
import { fadeAnimation } from '@app/app/animations/fade/fade.animation';

interface SearchResult {
  borrower: any;
  primaryContactName: string;
  businessName: string;
  routerLink?: string;
}

@Component({
  selector: 'app-applications-search',
  templateUrl: './applications-search.component.html',
  styleUrls: ['./applications-search.component.scss'],
  animations: [fadeAnimation],
  standalone: false
})
export class ApplicationsSearchComponent implements OnInit {
  @Input() color: 'primary' | 'accent' | 'warn' = 'primary'
  searchTerm: string | number | boolean;
  hasSearched = false;
  searchResults: SearchResult[] = [];
  searching = false;
  errored = false;

  constructor(private applicationsService: ApplicationsListService) {
    this.search = throttle(this.search, 300);
  }

  ngOnInit(): void {
    moment.tz.setDefault('America/Denver');
  }

  search() {
    if (this.searchTerm &&
      typeof this.searchTerm === 'string' &&
      this.searchTerm.length >= 3) {
      this.searching = true;
      this.applicationsService.searchApplications(this.searchTerm).pipe(take(1)).subscribe((res) => {
        this.searching = false;
        this.hasSearched = true;
        this.errored = false;

        if (res.data && res.data.length === 0) {
          this.searchResults = [];
        } else {
          this.searchResults = res.data.map((result: any) => {
            const routerLink = `deal/${result?.dealId}`
            return {
              result,
              routerLink,
              primaryContactName: result.cname,
              businessName: result.name,
            }
          });
        }
      }, (res) => {
        this.searchResults = [];
        this.searching = false;
        this.errored = true;
        this.hasSearched = true;
      });
    }
  }

  timeAgo(dateStamp: moment.MomentInput) {
    return moment(dateStamp).fromNow();
  }
}
