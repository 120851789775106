import { Component, computed, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Business } from '@app/app/interfaces/business.model';
import { DealActivity } from '@app/app/interfaces/deal-activity.model';
import { Deal } from '@app/app/interfaces/deal.model';
import { Document } from '@app/app/interfaces/document.model';
import { FundingDeskRequest } from "@app/app/interfaces/funding-desk-request.model";
import { Offer } from '@app/app/interfaces/offer.model';
import { BreadcrumbService } from '@app/app/services/breadcrumb.service';
import { ActivateDealPageTabEvent, OpenOverridesEvent } from '@app/app/services/event-types';
import { PageTitleDataService } from '@app/app/services/page-title-data.service';
import { AuthUser } from '@app/app/store/auth/auth-user';
import { AuthState } from '@app/app/store/auth/auth.state';
import { BusinessActions } from '@app/app/store/businesses/business.actions';
import { BusinessState } from '@app/app/store/businesses/business.state';
import { GetContractRequestOffers } from '@app/app/store/contract-requests/contract-requests.actions';
import { ContractRequestsState } from '@app/app/store/contract-requests/contract-requests.state';
import { GetDealActivities } from '@app/app/store/deal-activities/deal-activities.actions';
import { DealActivitiesState } from '@app/app/store/deal-activities/deal-activities.state';
import { ClearDealNotesStore, GetDealNotes } from '@app/app/store/deal-notes/deal-notes.actions';
import {
  GetBorrowerDecisioningByDeal,
  GetDecisioningResultForBorrower,
  RetryDecisioning,
} from '@app/app/store/decisioning/decisioning.actions';
import { DocumentsActions as DA } from '@app/app/store/documents/documents.actions';
import { DocumentsState } from '@app/app/store/documents/documents.state';
import { GetFundingDeskRequests } from '@app/app/store/funding-desk/funding-desk-requests.actions';
import { FundingDeskRequestsState } from "@app/app/store/funding-desk/funding-desk-requests.state";
import {
  PushToLaserPro,
  SubscribeToLaserPro,
  UnsubscribeFromLaserPro
} from "@app/app/store/laser-pro/laser-pro.actions";
import { ClearApprovalsStore, GetApprovals } from '@app/app/store/offers/approvals.actions';
import { ApprovalsState } from '@app/app/store/offers/approvals.state';
import { GetDealOffers } from '@app/app/store/offers/offers.actions';
import { OffersState } from '@app/app/store/offers/offers.state';
import { SaasFeaturesState } from '@app/app/store/saas-features/saas-features.state';
import { Store } from '@ngxs/store';
import { combineLatest, map, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { Approval } from 'src/app/interfaces/approval/approval.model';
import { ApplicationDetails } from '../../../interfaces/application-details.model';
import { Label } from '../../../interfaces/label.model';
import { EventBusService } from '../../../services/event-bus.service';
import { GetAdverseActionNotice } from '../../../store/adverse-action-notice/adverse-action-notice.actions';
import {
  ClearApplicationDetailsStore,
  GetApplicationDetails,
  GetApplicationFields,
} from '../../../store/application-details/application-details.actions';
import { ApplicationDetailsState } from '../../../store/application-details/application-details.state';
import { GetLabels } from '../../../store/labels/labels.actions';
import { LabelsState } from '../../../store/labels/labels.state';
import { GetPortalUsers } from '@app/app/store/portal-users/portal-users.actions';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss'],
  standalone: false
})
export class ApplicationDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) dealId: number;
  @Input() tabToActivate: string;
  @Input() inLenderTasksContext = false;

  contractRequests = this.store.selectSignal( ContractRequestsState.contractRequests );
  dealActivities$: Observable<DealActivity[]> = this.store.select( DealActivitiesState.activities );
  hasDecisioningFeature$: Observable<boolean> = this.store.select( SaasFeaturesState.saasPermitted('decisioning') );

  canPushToLaserPro = toSignal( this.store.select( AuthState.userHasPermission('canPushToLaserPro') ) );
  canSeeAdvDocMgmt = this.store.selectSignal<boolean>(SaasFeaturesState.saasPermitted('advDocMgmt', 'lpxCanSeeAdvDocMgmt'));
  isMarketplaceOrEmbeddedDeal$: Observable<boolean>;
  applicationDetails$: Observable<ApplicationDetails> = this.store.select(ApplicationDetailsState.applicationDetails);
  applicationFields$: Observable<any> = this.store.select(ApplicationDetailsState.applicationFields);
  lenderLabels$: Observable<Label[] | null> = this.store.select(LabelsState.labels);
  businessTenantId$: Observable<number | null> = this.store.select(BusinessState.businessTenantId);
  business$ = this.store.select(BusinessState.business);
  businessLenderId$: Observable<number | undefined> = this.store.select(BusinessState.businessLenderId);
  documents$: Observable<Document[] | null> = this.store.select(DocumentsState.documents);
  offers$: Observable<Offer[] | null> = this.store.select(OffersState.offers);
  approvals$: Observable<Approval[] | null> = this.store.select(ApprovalsState.approvals);
  fundingDeskRequests$: Observable<FundingDeskRequest[]> = this.store.select(FundingDeskRequestsState.fundingDeskRequests);

  dealContractRequest = computed(() => {
    const contractRequests = this.contractRequests() || [];
    return contractRequests.find(contractRequest => contractRequest.dealId === this.dealId );
  });

  navV2 = this.store.selectSignal(AuthState.userHasPermission('NavigationV2DarkLaunch'));

  private destroyed$ = new Subject<void>();

  portalUser: AuthUser | undefined;
  borrowerId: number;
  loading = true;
  loanProductCategory: string;
  loanProductId: number;
  loanProductType: string;
  loanProductTypeName: string;
  hasOffers: boolean;
  isRenewal = false;
  selectedIndex = 0;
  page: string;
  link: string;
  renewals: number[] = [0];
  tenantId: number | null;
  assignmentArray = [];
  tabs = ['details', 'documents', 'offers', 'approvals'];
  shouldShowContractRequestTab = false;
  shouldAutoPopOverrides = false;
  hasContractRequest = false;
  businessName: string;
  borrowerName: string;
  deal: Deal;

  constructor(
    private store: Store,
    private eventBusService: EventBusService,
    private router: Router,
    private _titleService: PageTitleDataService,
    private _breadcrumbService: BreadcrumbService,
  ) {
    if (localStorage.getItem('app.renewals')) {
      this.renewals = JSON.parse(localStorage.getItem('app.renewals') || '');
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.renewals = this.router.getCurrentNavigation()?.extras.state?.renewals
        ? this.router.getCurrentNavigation()?.extras.state?.renewals
        : [];
      localStorage.setItem('app.renewals', JSON.stringify(this.renewals));
    }
    this.portalUser = this.store.selectSnapshot(AuthState.user);
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnInit(): void {
    this.setUpStreams();
    this.isRenewal = this.renewals.includes(Number(this.dealId));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dealId) {
      this.loading = true;

      this.fetchData();

      this.applicationDetails$
        .pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe((applicationDetails) => {
          if (applicationDetails) {
            this.hasOffers = applicationDetails.deal.offers_desc.length > 0;
            this.loanProductCategory =
              applicationDetails.deal.loan_product.category.shortName;
            this.loanProductType = applicationDetails.deal.loan_product.type;
            this.loanProductTypeName = applicationDetails.deal.loan_product.loanProductTypeName;
            this.loanProductId = applicationDetails.deal.loan_product.id;
            this.assignmentArray = JSON.parse(
              applicationDetails.deal.assignments
            );
            this.deal = applicationDetails.deal;
            this.borrowerId = applicationDetails?.deal?.borrowerId;
            const title = applicationDetails.deal.borrower.name;
            const secondaryTitle = applicationDetails.deal.loan_product.name;
            const href = `/businesses/${applicationDetails.deal.borrower.id}`;
            this._titleService.setTitleData({ title, secondaryTitle, href });
            this.loading = false;
            this.hasContractRequest = !!this.dealContractRequest();
            this.businessName = applicationDetails.deal.borrower.name + ': ' + applicationDetails.deal.loan_product.name || 'Unknown';
            this.borrowerName = applicationDetails.deal.borrower.name;

            if (!this.page) {
              this.page = this.titleCase(applicationDetails.page);
              this.link = applicationDetails.page;
              /* This breadcrumb is mislabeled if the user is coming from the Businesses page
                  it should say Businesses > Business-Name, but it always says Funnel > Funded
                  the long term fix for this is to update the breadcrumbs to use the router instead
                  of manually setting them here.
               */
              this._breadcrumbService.setBreadcrumbs([
                {label: 'Funnel', path: '/funnel'},
              ]);
            }
          }

          // subscribe to laser pro updates
          if (this.canPushToLaserPro()) {
            this.store.dispatch(new SubscribeToLaserPro(this.dealId));
          }

          if (this.tabToActivate) {
            this.activateTabFromName(this.tabToActivate);
          }
        });
    }
  }

  setUpStreams(): void {
    // Decide whether to add "contractRequests", "decisioning" to tabs array
    combineLatest([this.businessTenantId$, this.applicationDetails$, this.hasDecisioningFeature$]).pipe(
      takeUntil(this.destroyed$),
      // Filter initial emissions from NGXS which are undefined
      filter(([businessTenantId, applicationDetails, hasDecisioningFeature]) => {
        return businessTenantId !== undefined
          && applicationDetails !== undefined
          && hasDecisioningFeature !== undefined;
      }),
      tap(([businessTenantId, applicationDetails, hasDecisioningFeature]) => {
        // contractRequests tab
        if (businessTenantId === 1 && [
          'ach',
          'loc',
          'term',
        ].includes(applicationDetails?.deal?.loan_product?.type)) {
          this.shouldShowContractRequestTab = true;
          this.tabs.push('contractRequests');
        }

        // decisioning tab
        if (hasDecisioningFeature) {
          // Fetch decisioning data since the tab will be shown
          if (applicationDetails !== null) {
            this.fetchDecisioningData(applicationDetails);
          }

          this.tabs.push('decisioning');
        }

        // history tab - always present and last
        this.tabs.push('history');
      }),
    ).subscribe();

    // Figure out if this a marketplace or embedded deal, and if so, fetch funding desk requests
    this.isMarketplaceOrEmbeddedDeal$ = this.business$.pipe(
      map((business: Business) => {
        return (business?.tenantId === 1 || (business?.isEmbedded ?? false));
      }),
      tap((isMarketplaceOrEmbeddedDeal) => {
        if (isMarketplaceOrEmbeddedDeal) {
          this.store.dispatch(new GetFundingDeskRequests(this.dealId));
        }
      })
    );

    // Navigate to "Offers" tab when a new offer is created
    this.eventBusService
      .getObservableForEvent(this.eventBusService.types.NewOfferEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.activateTabFromName('offers'));

    // Navigate to dynamic tab when a tab activation event is fired
    this.eventBusService
      .getObservableForEvent(this.eventBusService.types.ActivateDealPageTabEvent)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ tabName }: ActivateDealPageTabEvent) =>
        this.activateTabFromName(tabName)
      );
  }

  activateTabFromName(tabName: string): void {
    const index = this.tabs.indexOf(tabName.toLowerCase());
    if (index !== -1) {
      this.selectedIndex = index;
    }
  }

  fetchData() {
    if (this.portalUser == null) {
      return;
    }

    this.store.dispatch(new ClearApplicationDetailsStore());
    this.store.dispatch(new BusinessActions.ClearBusinessState());
    this.store.dispatch(new GetPortalUsers());
    this.store.dispatch(new GetLabels());

    if (this.dealId) {
      this.store.dispatch(new GetContractRequestOffers(this.dealId));
      this.store.dispatch(new GetDealActivities(this.dealId));
      this.store.dispatch(new GetApplicationDetails(this.dealId)).pipe(
        catchError((error) => {
          this.loading = false;
          return throwError(() => error);
        })
      ).subscribe({
        // Ensure we have the borrower's lender ID before we get documents.
        next: () => {
          const business = this.store.selectSnapshot(BusinessState.business);
          const appDetails = this.store.selectSnapshot(ApplicationDetailsState.applicationDetails);

          // Clear docs and reload based on switching deals, borrowers, etc.
          this.store.dispatch(new DA.ClearDocumentsState());
          this.store.dispatch(new DA.GetDocumentRequirements(this.dealId));
          if (!business?.id || business.id !== appDetails?.deal?.borrowerId) {
            if (appDetails?.deal?.borrowerId) {
              this.store.dispatch(new BusinessActions.GetBusiness(appDetails.deal.borrowerId)).subscribe({
                // Get document once we're sure we have a business' lenderId.
                next: () => {
                  // TODO: remove this while killing EntityState pattern
                  const lenderId = business?.lenderId;
                  if (this.canSeeAdvDocMgmt()) {
                    this.store.dispatch(new DA.GetAdvancedDealDocuments(this.dealId, lenderId!));
                  } else {
                    this.store.dispatch(new DA.GetDealDocuments(this.dealId, lenderId!));
                  }
                }
              });
            }
          } else {
            if (this.canSeeAdvDocMgmt()) {
              this.store.dispatch(new DA.GetAdvancedDealDocuments(this.dealId, business.lenderId as number));
            } else {
              this.store.dispatch(new DA.GetDealDocuments(this.dealId, business.lenderId as number))
            }
          }
        },
      });

      this.store.dispatch(new GetDealOffers(this.dealId))
      this.store.dispatch(new GetApplicationFields(this.dealId));
      this.store.dispatch(new GetAdverseActionNotice(this.dealId));
      this.store.dispatch(new GetDealNotes(this.dealId));
      this.store.dispatch(new GetApprovals(this.dealId));
    }
  }

  fetchDecisioningData(applicationDetails: ApplicationDetails): void {
    this.store.dispatch(new GetDecisioningResultForBorrower(
      applicationDetails?.deal?.borrowerId,
      applicationDetails?.deal?.loan_product?.id
    ));
    this.store.dispatch(new GetBorrowerDecisioningByDeal(
      applicationDetails?.deal?.borrowerId,
      applicationDetails?.deal?.id)
    );
  }

  /**
   * Ng lifecycle
   * @return void
   */
  ngOnDestroy(): void {
    this.store.dispatch(new ClearApplicationDetailsStore());
    this.store.dispatch(new ClearApprovalsStore());
    this.store.dispatch(new ClearDealNotesStore());
    if (this.canPushToLaserPro()) {
      this.store.dispatch(new UnsubscribeFromLaserPro(this.dealId));
    }
    this.destroyed$.next();
    this._breadcrumbService.setBreadcrumbs([]);
  }

  goBack() {
    if (this.isRenewal) {
      this.router.navigate([`/renewals/${this.link}`]);
    } else {
      this.router.navigate([`/applications/${this.link}`]);
    }
  }

  titleCase(s: string) {
    return s
      .replace(/([^A-Z])([A-Z])/g, '$1 $2') // split cameCase
      .replace(/[_-]+/g, ' ') // split snake_case and lisp-case
      .toLowerCase()
      .replace(/(^\w|\b\w)/g, function (m) {
        return m.toUpperCase();
      }) // title case words
      .replace(/\s+/g, ' ') // collapse repeated whitespace
      .replace(/^\s+|\s+$/, ''); // remove leading/trailing whitespace
  }

  // If user navigates away from Decisioning tab, make sure
  // we turn off the autopop flag so we don't automatically pop it
  // when we don't mean to.
  handleSelectedTabChange(tabChange: MatTabChangeEvent) {
    if (this.tabs[tabChange.index] !== 'decisioning') {
      this.shouldAutoPopOverrides = false;
    }
  }

  handleOverrideButtonClicked() {
    if (this.tabs[this.selectedIndex] === 'decisioning') {
      // Fire event so Decisioning tab knows to open Overrides flow
      this.eventBusService.publish(new OpenOverridesEvent());
    } else {
      // Activate Decisioning tab and trigger autopop via input
      // since it's not yet subscribed to events.
      this.shouldAutoPopOverrides = true;
      this.activateTabFromName('decisioning');
    }
  }

  handlePushToLaserProButtonClicked() {
    this.store.dispatch(new PushToLaserPro(this.dealId, this.businessName));
  }

  handleOpenDealHistoryClicked() {
    this.activateTabFromName('history');
  }

  /**
   * Move the users tab to decisioning if/when retry decisioning is selected
   */
  handleRetryDecisioningClicked() {
    if (this.tabs[this.selectedIndex] === 'decisioning') {
    } else {
      this.activateTabFromName('decisioning');
    }

    this.store.dispatch(new RetryDecisioning(this.borrowerId, this.dealId));
  }

}
