import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetDirectiveModule } from '@app/app/directives/let/let.directive.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SearchInputComponent } from '@app/app/components/search/search-input/search-input.component';
import { TimeAgoModule } from '@app/app/pipes/time-ago/time-ago-pipe.module';
import { FadeAnimationDirective } from '@app/app/animations/fade/fade-animation.directive';

@NgModule({
  declarations: [
    SearchInputComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        LetDirectiveModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        ReactiveFormsModule,
        RouterModule,
        TimeAgoModule,
        FadeAnimationDirective,
    ],
  exports: [
    SearchInputComponent,
  ],
})
export class SearchModule {}
