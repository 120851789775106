import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'entityTypeDisplay'
})
export class EntityTypeDisplayPipe implements PipeTransform {
  private readonly entityTypeMap: Record<string, string> = {
    llc: 'LLC',
    corporation: 'Corporation',
    soleproprietor: 'Sole Proprietor',
    legalpartnership: 'Legal Partnership'
  };

  transform(input: string | null | undefined): string {
    if (!input) {
      return '';
    }

    const normalizedInput = input.trim().toLowerCase();
    return this.entityTypeMap[normalizedInput] || input;
  }
}
