import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Approval } from '../interfaces/approval/approval.model';
import { Offer } from '../interfaces/offer.model';
import { ResponseObj } from '../interfaces/response-obj';
import { OptionType } from '../interfaces/option.model';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  private apiUrl = environment.apiUrl;
  private getUrl = environment.apiUrl + '/l/v1/internal';

  constructor(private http: HttpClient) { }

  getDealOffers(dealId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/l/v2/internal/deals/${dealId}/offers`);
  }

  getOffers(borrowerId: number): Observable<Approval[]> {
    return this.http.get<Approval[]>(`${this.apiUrl}/approval/borrower/${borrowerId}`);
  }

  getOffersCount(borrowerId: number): Observable<any> {
    return this.http.get<Approval[]>(`${this.apiUrl}/approval/borrower/${borrowerId}/count`);
  }

  createOffer(dealId: number, offerPayload: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/l/v1/internal/deals/${dealId}/offers`, offerPayload);
  }

  updateOffer(offerId: number, offerPayload: Partial<Offer>) {
    return this.http.put<ResponseObj>(`${this.apiUrl}/offer/${offerId}`, offerPayload)
      .pipe(map((response) => response.data as Offer));
  }

  createPPPOffer(dealId: number, offerPayload: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/deals/${dealId}/ppp-offer`, offerPayload);
  }

  resendContract(dealId: number, offerId: number): Observable<any>  {
    return this.http.put<any>(`${this.apiUrl}/deals/${dealId}/ppp-offer/${offerId}`, {});
  }

  getApprovalsByDealId(dealId: number): Observable<any> {
    return this.http.get<Approval[]>(`${this.getUrl}/deals/${dealId}/approvals`);
  }

  getApprovalsCountByDealId(dealId: number): Observable<any> {
    return this.http.get<Approval[]>(`${this.apiUrl}/approval/deal/${dealId}/count`);
  }

  getAdjustedApproval(approvalId: number,
    amount: number,
    term: number,
    points: number,
    frequency: string,
    origination: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/approval/${approvalId}/adjustments?
      amount=${amount}&term=${term}&points=${points}&frequency=${frequency}&origination=${origination}`);
  }

  publishOption(optionId: number, type: OptionType): Observable<ResponseObj> {
    return this.http.put<ResponseObj>(`${this.apiUrl}/partner-portal/options/${type}/${optionId}/publish`, null);
  }
  
  unpublishOption(optionId: number, type: OptionType): Observable<ResponseObj> {
    return this.http.put<ResponseObj>(`${this.apiUrl}/partner-portal/options/${type}/${optionId}/unpublish`, null);
  }
}
