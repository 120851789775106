@if (navV2()) {
    <div class="sidenav-user-menu-wrapper-2" #sidenavUserComponent>
        <div class="sidenav-user-menu"
             [class.menu-open]="sidenavUserMenuState() === 'open'"
             [@menuGrow]="sidenavUserMenuState()">
            @if (sidenavUserMenuState() === 'open') {
                <button class="sidenav-user-menu-item"
                        [appFadeAnimation]
                        [fadeEnterDurationInMs]="200"
                        [fadeEnterDelayInMs]="200"
                        [fadeLeaveDurationInMs]="200"
                        [fadeLeaveDelayInMs]="0"
                        (click)="selectSettings()">
                    <mat-icon class="sidenav-user-menu-item-icon">settings</mat-icon>
                    <div class="sidenav-user-menu-item-label">Settings</div>
                </button>
                <button class="sidenav-user-menu-item"
                        [appFadeAnimation]
                        [fadeEnterDurationInMs]="200"
                        [fadeEnterDelayInMs]="200"
                        [fadeLeaveDurationInMs]="200"
                        [fadeLeaveDelayInMs]="0"
                        (click)="selectLogout()">
                    <mat-icon class="sidenav-user-menu-item-icon">power_settings_new</mat-icon>
                    <div class="sidenav-user-menu-item-label">Logout</div>
                </button>
            }
        </div>
        <button class="sidenav-user-menu-trigger"
                [class.menu-open]="sidenavUserMenuState() === 'open'"
                (click)="toggleSidenavMenu()"
                [@buttonGrow]="{value: sidenavUserMenuState(), params: { openWidth: menuOpenWidth() } }">
            <div class="sidenav-user-menu-button-content">
                <mat-icon class="sidenav-user-button-icon"
                          [class.sba-specialist]="isSbaSpecialist()"
                          [class.menu-open]="sidenavUserMenuState() === 'open'"
                          fontSet="material-icons-outlined">
                    perm_identity
                </mat-icon>
                @if (saasFeatureBadgeEnabled()) {
                    <app-saas-new-feature-badge></app-saas-new-feature-badge>
                }
            </div>
            <div #sidenavUserMenuText class="sidenav-user-menu-text">
                <div class="sidenav-user-menu-username">
                    {{ userFullName }}
                </div>
                <div class="sidenav-user-menu-institution">
                    {{ organizationName }}
                    @if (isSbaSpecialist()) {
                        <div class="user-label sba-specialist">- SBA Specialist</div>
                    }
                </div>
            </div>
        </button>
    </div>

} @else {
    @if (collapsed || navMenuCollapsed) {
        <div class="sidenav-user-container">
            <button class="sidenav-user-menu-button"
                    #userSettingsMenuTrigger="matMenuTrigger"
                    [class.active]="userSettingsMenuTrigger.menuOpen"
                    [matMenuTriggerFor]="userSettingsMenu"
                    (menuOpened)="userSettingsMenuTrigger.menuOpen"
                    (menuClosed)="userSettingsMenuTrigger.menuClosed">
                <mat-icon class="user-avatar-icon" fontSet="material-icons-outlined">perm_identity</mat-icon>
            </button>
            <mat-menu #userSettingsMenu="matMenu"
                      [hasBackdrop]="true">
                <button mat-menu-item (click)="selectSettings()">
                    <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                    <span>Settings</span>
                </button>
                <button mat-menu-item (click)="selectLogout()">
                    <mat-icon>logout</mat-icon>
                    <span>Sign out</span>
                </button>
            </mat-menu>
        </div>
    } @else {
        <div class="sidenav-user-container">
            <button class="sidenav-user-menu-button"
                    #userSettingsMenuTrigger="matMenuTrigger"
                    [class.active]="userSettingsMenuTrigger.menuOpen"
                    [matMenuTriggerFor]="userSettingsMenu"
                    (menuOpened)="userSettingsMenuTrigger.menuOpen"
                    (menuClosed)="userSettingsMenuTrigger.menuClosed">
                <mat-icon class="user-avatar-icon"
                          fontSet="material-icons-outlined">
                    <span>perm_identity</span>
                </mat-icon>
                <div class="sidenav-container-user-info">
                    <span class="sidenav-username">{{ userFullName }}</span>
                    @if (organizationName) {
                        <span class="sidenav-institution">{{ organizationName }}</span>
                    }
                    @if (isSbaSpecialist()) {
                        <div class="border rounded bg-lendio-yellow-400 text-xs px-2 my-1 font-semibold leading-tight">SBA Specialist</div>
                    }
                </div>
                <mat-icon class="user-settings-icon"
                          fontSet="material-icons-outlined">
                    <span>settings</span>
                </mat-icon>
            </button>
            @if (saasFeatureBadgeEnabled()) {
                <app-saas-new-feature-badge></app-saas-new-feature-badge>
            }
            <mat-menu #userSettingsMenu="matMenu"
                      [hasBackdrop]="true">
                <ng-template matMenuContent>
                    <div [style.width.px]="263">
                        <button mat-menu-item
                                (click)="selectSettings()">
                            <mat-icon fontSet="material-icons-outlined">settings</mat-icon>
                            <span>Settings</span>
                        </button>
                        <button mat-menu-item
                                (click)="selectLogout()">
                            <mat-icon>logout</mat-icon>
                            <span>Sign out</span>
                        </button>
                    </div>
                </ng-template>
            </mat-menu>
        </div>
    }
}
