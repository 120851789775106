import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ContractRequestsService } from 'src/app/services/contract-requests.service';
import { CreateNewAlert } from '../global-alerts/global-alerts.actions';
import {
  ClearContractRequestStore,
  CreatePreviewContractRequest,
  GetContractRequestOffers
} from './contract-requests.actions';

export class  ContractRequestsStateModel {
    contractRequests: any[];
    loading: boolean;
}

@State<ContractRequestsStateModel>({
    name: 'contractRequests',
    defaults: {
        contractRequests: [],
        loading: false
    }
})
@Injectable()

export class ContractRequestsState
{
    @Selector() static contractRequests(state: ContractRequestsStateModel) {
        return state.contractRequests;
    }

    @Selector() static loading(state: ContractRequestsStateModel) {
        return state.loading;
    }

    constructor(
        private contractRequestsService: ContractRequestsService,
        private store: Store
    ) {}

    @Action(GetContractRequestOffers)
    GetContractRequestOffers(
      ctx: StateContext<ContractRequestsStateModel>,
      { dealId }: GetContractRequestOffers
    ) {
      ctx.patchState({loading: true});
      return this.contractRequestsService
        .getDealContractRequest(dealId)
          .pipe(
            catchError((err: any) => {
              this.store.dispatch(new CreateNewAlert({
                  level: 'error',
                  message: 'Unable to retrieve contract requests. Please refresh the page to try again.'
              }));
              ctx.patchState({loading: false});
              return throwError(err);
            }),
            /**
             * This endpoint <somehow> returns a single contract request instead of a collection
             * and since it's in lender routes it could be in use by Lenders so we can't change it.
             */
            tap((response) => {
              const contractRequests = response.data?.id ? [response.data] : [];
              ctx.setState(patch({
                contractRequests,
                loading: false,
              }));
            })
          );
    }

    @Action(ClearContractRequestStore)
    clearContractRequestsStore({ patchState }: StateContext<ContractRequestsStateModel>, {}: ClearContractRequestStore) {
        patchState({contractRequests: undefined});
    }

    @Action(CreatePreviewContractRequest)
    CreatePreviewContractRequest(
      ctx: StateContext<ContractRequestsStateModel>,
      { dealId }: CreatePreviewContractRequest
    ) {
      ctx.patchState({loading: true});
      return this.contractRequestsService.createPreviewContractRequest(dealId)
        .pipe(
          catchError(err => {
            throw err;
          }),
          tap((response) => {
            ctx.setState(patch({
              loading: false,
              contractRequests: append([response.data]),
            }));
          }),
        );
    }

}
