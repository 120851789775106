<nav mat-tab-nav-bar [tabPanel]="settingsNavTabPanel" [class.nav-v2]="navV2()">
    @for (tab of tabs; track tab.name) {
        <a mat-tab-link
           routerLinkActive
           #rla="routerLinkActive"
           *ngIf="tab.permitted"
           [active]="rla.isActive"
           [routerLink]="tab.path"
           [disableRipple]="true"
           data-cy="email-notifications-tab">
            {{ tab.label }}
            @if (tab.label === 'Early access' && showEarlyAccessBadge()) {
                <span class="early-access-tab-badge"></span>
            }
        </a>
    }
</nav>
<mat-tab-nav-panel #settingsNavTabPanel>
    <router-outlet></router-outlet>
</mat-tab-nav-panel>
