import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { LendioResponse } from '@app/app/interfaces/lendio-response';
import { AgGridViewFilter } from '@app/app/store/funnel-views/funnel-views.state';
import { AgGridTable } from '../components/funnel/funnel-grid/funnel-table.enum';

const funnelViewFiltersUrl = `${environment.apiUrl}/partner-portal/ag-grid-views`;

export interface AgGridViewsResponse {
  data: {
    agGridTableName: string;
    agGridViews: AgGridViewFilter[]
  };
}

@Injectable({
  providedIn: 'root'
})
export class FunnelViewFiltersService {
  constructor(private _http: HttpClient) { }

  getFunnelViews(funnelTable: AgGridTable): Observable<AgGridViewsResponse> {
    return this._http.get<AgGridViewsResponse>(`${funnelViewFiltersUrl}/${funnelTable}`);
  }

  updateFunnelViews(viewFilters: AgGridViewFilter[], tableName: AgGridTable): Observable<LendioResponse> {
    const funnelViews: AgGridViewFilter[] = [];
    viewFilters.forEach(viewFilter => {
      funnelViews.push({
        name: viewFilter.name,
        isCreatedByUser: viewFilter.isCreatedByUser,
        isHiddenByUser: viewFilter.isHiddenByUser,
        agGridViewId: viewFilter.agGridViewId,
        filterModel: viewFilter.filterModel
      })
    })
    return this._http.patch<LendioResponse>(`${funnelViewFiltersUrl}/${tableName}`, { funnelViews });
  }
}
