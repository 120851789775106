import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service for getting common lists of options for form elements like <select>.
 */
@Injectable({
  providedIn: 'root'
})
export class FormOptionsService {

  public hubSpotForm = new Subject<boolean>();

  constructor() {}

  /**
   * Get a list of entity types for borrower attribute ID 676
   * (business_property_type_own_rent).
   */
  businessPropertyTypeOwnRent(): Array<any> {
    return [
      { label: 'Lease', value: 'lease' },
      { label: 'Mortgage', value: 'mortgage' },
      { label: 'Own', value: 'own' },
    ];
  }
  // Access by attributeId.
  ba676() {
    return this.businessPropertyTypeOwnRent();
  }

  /**
   * Get a list of entity types for borrower attribute 234 ID
   * (business_location_type).
   */
  businessLocationType(): Array<any> {
    return [
      { label: 'Commercial', value: 'commercial' },
      { label: 'Home', value: 'home' },
      { label: 'Online', value: 'online' },
    ];
  }
  // Access by attributeId.
  ba234() {
    return this.businessLocationType();
  }

  /**
   * Get a list of entity types for borrower attribute ID 230 (entity_type).
   */
  entityTypes(): Array<any> {
    return [
      { label: 'LLC', value: 'llc' },
      { label: 'Corporation', value: 'corporation' },
      { label: 'Sole Proprietor', value: 'soleProprietor' },
      { label: 'Legal Partnership', value: 'legalPartnership' },
    ];
  }

  /**
   * Get a list of entity types for borrower attribute ID 732 (lendioIndustry).
   *
   * Pasted from the borrower attribute and just leaving naics in case anyone
   * needs it.
   */
  lendioIndustry(): Array<any> {
    return [
        {
          label: 'Agriculture, Forestry, Fishing and Hunting',
          value: 'agricultureForestry',
          naics: 11,
        },
        {
          label: 'Arts, Entertainment, and Recreation',
          value: 'artsEntertainment',
          naics: 71,
        },
        {
          label: 'Adult Entertainment',
          value: 'adultEntertainment',
          naics: 71,
        },
        { label: 'Gambling', value: 'gambling', naics: 7132 },
        {
          label: 'Automobile Dealers & Parts',
          value: 'automotive',
          naics: 441,
        },
        { label: 'Construction', value: 'construction', naics: 23 },
        { label: 'Ecommerce', value: 'ecommerce', naics: 454110 },
        { label: 'Education', value: 'education', naics: 61 },
        { label: 'Finance and Insurance', value: 'finance', naics: 52 },
        { label: 'Healthcare', value: 'healthcare', naics: 62 },
        { label: 'Social Assistance', value: 'socialAssistance', naics: 624 },
        {
          label: 'IT, Media, or Publishing',
          value: 'informationMedia',
          naics: 51,
        },
        { label: 'Legal Services', value: 'legalServices', naics: 5411 },
        { label: 'Mining (except Oil and Gas)', value: 'mining', naics: 21 },
        { label: 'Oil and Gas Extraction', value: 'oilGas', naics: 211 },
        { label: 'Manufacturing', value: 'manufacturing', naics: 31 },
        {
          label: 'Political, Governmental, or Public Organizations',
          value: 'governmentPublic',
          naics: 92,
        },
        { label: 'Real Estate', value: 'realEstate', naics: 53 },
        {
          label: 'Religious Organizations',
          value: 'religiousOrganizations',
          naics: 8131,
        },
        {
          label: 'Restaurants and Food Services',
          value: 'restaurants',
          naics: 722,
        },
        { label: 'Retail Stores', value: 'retail', naics: 44 },
        { label: 'Firearm Sales', value: 'firearms', naics: 452 },
        { label: 'Gas Stations', value: 'gasStations', naics: 447 },
        {
          label: 'Transportation and Warehousing',
          value: 'transportation',
          naics: 48,
        },
        { label: 'Freight Trucking', value: 'freightTrucking', naics: 484 },
        { label: 'Travel Agencies', value: 'travelAgencies', naics: 5616 },
        { label: 'Utilities', value: 'utilities', naics: 22 },
        { label: 'Wholesale Trade', value: 'wholesale', naics: 42 },
        { label: 'All Other', value: 'other', naics: null },
    ];
  }
  // Access by attributeId.
  ba732() {
    return this.lendioIndustry();
  }

  /**
   * Get a list of entity types for borrower attribute 86 ID (primaryCustomer).
   */
  primaryCustomer(): Array<any> {
    return [
      { label: 'Businesses', value: 'businesses' },
      { label: 'Consumers', value: 'consumers' },
      { label: 'Governments', value: 'governments' },
    ];
  }

  // Access by attributeId.
  ba86() {
    return this.primaryCustomer();
  }

  /**
   * Get standard list of states (50 + DC).
   */
  states(): Array<any> {
    // tslint:disable-next-line: no-unused-expression
    return [
      {
        value: 'AK',
        label: 'Alaska'
      },
      {
        value: 'AL',
        label: 'Alabama'
      },
      {
        value: 'AR',
        label: 'Arkansas'
      },
      {
        value: 'AS',
        label: 'American Samoa'
      },
      {
        value: 'AZ',
        label: 'Arizona'
      },
      {
        value: 'CA',
        label: 'California'
      },
      {
        value: 'CO',
        label: 'Colorado'
      },
      {
        value: 'CT',
        label: 'Connecticut'
      },
      {
        value: 'DC',
        label: 'District Of Columbia'
      },
      {
        value: 'DE',
        label: 'Delaware'
      },
      {
        value: 'FL',
        label: 'Florida'
      },
      {
        value: 'FM',
        label: 'Federated States of Micronesia'
      },
      {
        value: 'GA',
        label: 'Georgia'
      },
      {
        value: 'GU',
        label: 'Guam'
      },
      {
        value: 'HI',
        label: 'Hawaii'
      },
      {
        value: 'IA',
        label: 'Iowa'
      },
      {
        value: 'ID',
        label: 'Idaho'
      },
      {
        value: 'IL',
        label: 'Illinois'
      },
      {
        value: 'IN',
        label: 'Indiana'
      },
      {
        value: 'KS',
        label: 'Kansas'
      },
      {
        value: 'KY',
        label: 'Kentucky'
      },
      {
        value: 'LA',
        label: 'Louisiana'
      },
      {
        value: 'MA',
        label: 'Massachusetts'
      },
      {
        value: 'MD',
        label: 'Maryland'
      },
      {
        value: 'ME',
        label: 'Maine'
      },
      {
        value: 'MH',
        label: 'Marshall Islands'
      },
      {
        value: 'MI',
        label: 'Michigan'
      },
      {
        value: 'MN',
        label: 'Minnesota'
      },
      {
        value: 'MO',
        label: 'Missouri'
      },
      {
        value: 'MP',
        label: 'Northern Mariana Islands'
      },
      {
        value: 'MS',
        label: 'Mississippi'
      },
      {
        value: 'MT',
        label: 'Montana'
      },
      {
        value: 'NC',
        label: 'North Carolina'
      },
      {
        value: 'ND',
        label: 'North Dakota'
      },
      {
        value: 'NE',
        label: 'Nebraska'
      },
      {
        value: 'NH',
        label: 'New Hampshire'
      },
      {
        value: 'NJ',
        label: 'New Jersey'
      },
      {
        value: 'NM',
        label: 'New Mexico'
      },
      {
        value: 'NV',
        label: 'Nevada'
      },
      {
        value: 'NY',
        label: 'New York'
      },
      {
        value: 'OH',
        label: 'Ohio'
      },
      {
        value: 'OK',
        label: 'Oklahoma'
      },
      {
        value: 'OR',
        label: 'Oregon'
      },
      {
        value: 'PA',
        label: 'Pennsylvania'
      },
      {
        value: 'PR',
        label: 'Puerto Rico'
      },
      {
        value: 'PW',
        label: 'Palau'
      },
      {
        value: 'RI',
        label: 'Rhode Island'
      },
      {
        value: 'SC',
        label: 'South Carolina'
      },
      {
        value: 'SD',
        label: 'South Dakota'
      },
      {
        value: 'TN',
        label: 'Tennessee'
      },
      {
        value: 'TX',
        label: 'Texas'
      },
      {
        value: 'UT',
        label: 'Utah'
      },
      {
        value: 'VA',
        label: 'Virginia'
      },
      {
        value: 'VI',
        label: 'Virgin Islands'
      },
      {
        value: 'VT',
        label: 'Vermont'
      },
      {
        value: 'WA',
        label: 'Washington'
      },
      {
        value: 'WI',
        label: 'Wisconsin'
      },
      {
        value: 'WV',
        label: 'West Virginia'
      },
      {
        value: 'WY',
        label: 'Wyoming'
      }
    ];
  }

  /**
   * Get yes/no values that correspond to true/false.
   *
   * Note: the value must be a string for select inputs to render it correctly.
   */
  yesNo(): Array<any> {
    return [
      { label: 'Yes', value: "1" },
      { label: 'No', value: "0" },
    ];
  }

  setHubspotEnabled(bool: boolean): void {
    this.hubSpotForm.next(bool);
  }

}

