<div>
    <documents-header [documents]="documents"
                      [canUpload]="canUpload"
                      [canCrudMpDealDocs]="canCrudMpDealDocs()"
                      [currentUser]="authUser"
                      [hasModifyAccess]="hasModifyAccess"
                      [dealId]="dealId"
                      [selectedRows]="selectedRows()"
                      [isDownloading]="_isDownloading"
                      (handleDownloadEvent)="handleDownload($event)"
                      (handleUploadEvent)="handleUpload($event)"
                      (handleDeleteEvent)="handleDelete($event)">
    </documents-header>
</div>
<div class="documents-grid-container" id="documentsGridContainer">
    <ag-grid-angular class="ag-theme-quartz documents-grid border-none"
                     [rowData]="documents"
                     [gridOptions]="documentsGridOptions"
                     [initialState]="documentsGridInitialState"
                     (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
                     (firstDataRendered)="onFirstDataRendered($event)"
                     (gridReady)="onGridReady($event)"
                     (rowClicked)="onRowClicked($event)"
                     (rowDataUpdated)="onRowDataUpdated($event)"
                     (selectionChanged)="onSelectionChanged($event)"
                     (toolPanelVisibleChanged)="onToolPanelVisibilityChanged($event)">
    </ag-grid-angular>
</div>
