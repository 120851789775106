import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  GetDealOffers,
  PublishOffer,
  UnpublishOffer
} from 'src/app/store/offers/offers.actions';
import { OffersState } from 'src/app/store/offers/offers.state';
import { Observable } from 'rxjs';
import { Offer } from 'src/app/interfaces/offer.model';
import { ApplicationDetailsState } from 'src/app/store/application-details/application-details.state';
import { ApplicationDetails } from 'src/app/interfaces/application-details.model';
import { take } from 'rxjs/operators';
import { AuthState } from '@app/app/store/auth/auth.state';

@Component({
  selector: 'app-offers-smart',
  templateUrl: './offers-smart.component.html',
  styleUrls: ['./offers-smart.component.scss'],
  standalone: false
})
export class OffersSmartComponent implements OnInit {
  @Input() dealId: number;
  @Input() loanProductCategory: string;
  @Input() tenantId: number;
  applicationDetails$: Observable<ApplicationDetails | null> = this.store.select(ApplicationDetailsState.applicationDetails);
  offers$: Observable<Offer[] | null> = this.store.select(OffersState.offers);
  sbaLoanNumber: number;
  canPublishOffers$: Observable<boolean> = this.store.select(AuthState.userHasPermission('lpxPublishOption'));

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.dispatch(new GetDealOffers(this.dealId));
    this.applicationDetails$.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.sbaLoanNumber = Number(data.deal.sbaLoanNumber);
      }
    });
  }
  togglePublished([setStateTo, offerId]: [boolean, number]) {
    if (setStateTo === true) {
      this.store.dispatch(new PublishOffer(offerId));
    } else {
      this.store.dispatch(new UnpublishOffer(offerId));
    }
  }
}
