import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

export const dealStatusOptions = [
  {
    value: 'appInProgress',
    label: 'App In Progress',
    display: 'Application',
  },
  {
    value: 'appComplete',
    label: 'App Complete',
    display: 'Application',
  },
  {
    value: 'awaitingLender',
    label: 'Awaiting Lender',
    display: 'Underwriting',
  },
  {
    value: 'awaitingResponse',
    label: 'Awaiting Response',
    display: 'Underwriting',
  },
  {
    value: 'pendingFundingManager',
    label: 'Pending Funding Manager',
    display: 'Underwriting',
  },
  {
    value: 'declined',
    label: 'Declined',
    display: 'Underwriting',
  },
  {
    value: 'offerReceived',
    label: 'Offer Received',
    display: 'Underwriting',
  },
  {
    value: 'offerToBorrower',
    label: 'Offer To Borrower',
    display: 'Underwriting'
  },
  {
    value: 'offerAccepted',
    label: 'Offer Accepted',
    display: 'Underwriting'
  },
  {
    value: 'offerDeclined',
    label: 'Offer Declined',
    display: 'Underwriting'
  },
  {
    value: 'contractRequested',
    label: 'Contract Requested',
    display: 'Closing'
  },
  {
    value: 'contractOut',
    label: 'Contract Out',
    display: 'Closing'
  },
  {
    value: 'funding',
    label: 'Funding',
    display: 'Closing'
  },
  {
    value: 'current',
    label: 'Current',
    display: 'Funded'
  },
  {
    value: 'renewed',
    label: 'Renewed',
    display: 'Funded'
  },
  {
    value: 'behind',
    label: 'Behind',
    display: 'Funded'
  },
  {
    value: 'default',
    label: 'Default',
    display: 'Funded'
  },
  {
    value: 'paidOff',
    label: 'Paid Off',
    display: 'Funded'
  },
  {
    value: 'stacked',
    label: 'Stacked',
    display: 'Funded'
  },
  {
    value: 'lostDeal',
    label: 'Lost Deal',
    display: 'Inactive'
  },
  {
    value: 'lostContact',
    label: 'Lost Contact',
    display: 'Inactive'
  },
  {
    value: 'doesNotQualify',
    label: 'Does Not Qualify',
    display: 'Inactive'
  },
  {
    value: 'dead',
    label: 'Dead',
    display: 'Inactive'
  }
];

@Pipe({
  name: 'statusDisplay',
  standalone: false
})
export class DealStatusDisplayPipe implements PipeTransform {
  transform(value: string): string {
    if (typeof value === 'undefined' || value === null) {
      return '';
    }
    const statusOption = dealStatusOptions.find(el => el.value === value);
    return statusOption ? statusOption.display : _.startCase(_.camelCase(value));
  }
}

